import React, { useState } from "react";
import "./AccountDetail.css";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { update_profile } from "../../../../actions/user/accountInfo";

const AccountDetail = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const user = state.user.user;
  const isLoading = state.user.loading;
  const [userProfile, setUserProfile] = useState(null);

  const onChange = (e) => {
    userProfile
      ? setUserProfile({ ...userProfile, [e.target.name]: e.target.value })
      : setUserProfile({ ...user.profile, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(update_profile(userProfile));
  };
  const url = window.location.origin;
  return (
    <section className="account-detail">
      <h1 className="address-container">
        <i className="fas fa-envelope"></i>
        <input
          style={{ color: "tan"}}
          type="text"
          placeholder="Email"
          defaultValue={user && user.email}
          disabled
          autoComplete="off"
        />
      </h1>
      <h1 className="address-container">
        <i className="fas fa-user"></i>
        <input
          style={{ color: "tan" }}
          type="text"
          placeholder="Name"
          defaultValue={
            user && user.username.first_name + " " + user.username.last_name
          }
          disabled
          autoComplete="off"
        />
      </h1>

      <form onSubmit={(e) => onSubmit(e)} autoComplete="off">
        <h1 className="address-container">
          <i className="fas fa-phone"></i>
          <input
            style={{ color: "tan" }}
            defaultValue={user && user.profile.phone}
            type="phone"
            placeholder={t("phone number")}
            name="phone"
            autoComplete="off"
            onChange={(e) => onChange(e)}
          />
        </h1>

        <h1 className="address-container">
          <i style={{marginTop:"2px"}} className="fas fa-home"></i>
          <textarea
            style={{ color: "tan" }}
            defaultValue={user && user.profile.address}
            type="address"
            placeholder={t("Street and Number")}
            name="address"
            onChange={(e) => onChange(e)}
            autoComplete="off"
          />
        </h1>
        <h1 className="address-container">
        <i className="fas fa-hashtag"></i>
          <input
            style={{ color: "tan" }}
            defaultValue={user && user.profile.postcode}
            type="text"
            placeholder={t("Postal Code")}
            name="postcode"
            onChange={(e) => onChange(e)}
            autoComplete="off"
          />
        </h1>
        <h1 className="address-container">
        <i className="fas fa-flag "></i>
          <input
            style={{ color: "tan"}}
            defaultValue={user && user.profile.town}
            type="text"
            placeholder={t("Your City here")}
            name="town"
            onChange={(e) => onChange(e)}
            autoComplete="off"
          />
        </h1>
        <h1 className="address-container">
        <i className="fas fa-street-view"></i>
          <input
            aria-autocomplete="off"
            style={{ color: "tan" }}
            defaultValue={user && user.profile.province}
            type="text"
            placeholder={t("province")}
            name="province"
            onChange={(e) => onChange(e)}
            autoComplete="off"
          />
        </h1>
        <h1 className="address-container">
        <i className="fas fa-globe"></i>
          <input
            aria-autocomplete="off"
            style={{ color: "tan" }}
            defaultValue={user && user.profile.country}
            type="text"
            placeholder={t("Your Country here")}
            name="country"
            onChange={(e) => onChange(e)}
            autoComplete="off"
          />
        </h1>
        <button
          type="submit"
          className="btn login"
          style={{
            borderRadius: "1px",
            cursor: userProfile ? "pointer" : "not-allowed",
          }}
          disabled={userProfile ? false : true}
        >
          {isLoading ? (
            <Loader
              style={{ display: "inline-block" }}
              type="ThreeDots"
              color="white"
              height={5}
              width={30}
            />
          ) : (
            ""
          )}
          {t("Save Changes")}
        </button>
      </form>
      {user && user.profile.allow_affiliate ? (
        <>
          <h1>
            <span className="pr-2">
              {t("Affiliate Registration Promo Code")}
            </span>
            <i className="fas fa-share-alt"></i>
            <span>{user && user.affiliate_token}</span>
          </h1>
          <h1>
            <span className="pr-2">{t("Affiliate Link")}</span>
            <i className="fas fa-link"></i>
            <div>
              <h1>
                {url}/signup?token={user && user.affiliate_token}
              </h1>
            </div>
          </h1>
        </>
      ) : (
        <></>
      )}
    </section>
  );
};

export default AccountDetail;
