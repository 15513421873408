import "./toast.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export function SuccessToast(message) {
  toast(message, {
    className: "customized",
    bodyClassName: "customized",
  });
}

export function ErrorToast(message) {
  toast.error(message, {
    className: "customized",
  });
}
