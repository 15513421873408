import { combineReducers } from "redux";
import common from "./common";
import user from "./user";
import competitions from "./competitions";
import giftshop from "./giftshop";
import cart from "./cart";
import winnersPodium from "./winnersPodium";

export default combineReducers({
  common,
  user,
  competitions,
  giftshop,
  cart,
  winnersPodium,
});
