import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";

import "./signup.css";
import { signup } from "../../../../actions/user/auth";
import Navbar from "../../Landing-page/Navbar/navbar.jsx";
import { Field } from "../../../../components/fields.jsx";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignUp = ({ signup, isLoading, isAuthenticated, error }) => {
  let query = useQuery();

  const { i18n, t } = useTranslation();
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    password: "",
    last_name: "",
    first_name: "",
    re_password: "",
    code: query.get("token"),
  });
  const [activeField, setActiveField] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const fields = [
    {
      name: "first_name",
      labelKey: "First Name",
      placeholder: "Enter your first name",
      type: "text",
    },
    {
      name: "last_name",
      labelKey: "Family Name",
      placeholder: "Enter your family name",
      type: "text",
    },
    {
      name: "phone",
      labelKey: "Number",
      placeholder: "Phone Number",
      type: "text",
    },
    {
      name: "email",
      labelKey: "Email address",
      placeholder: "Enter your email",
      type: "email",
    },
    {
      name: "password",
      labelKey: "Password",
      placeholder: "Enter your password",
      type: passwordVisibility ? "text" : "password",
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    signup(formData);
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Navbar />
      <div className="signUp">
        <div className="form-modal">
          <div className="form-toggle">
            <button id="signup-toggle" disabled onclick="toggleLogin()">
              REGISTER
            </button>
          </div>
          <div id="signup-form">
            <form onSubmit={(e) => onSubmit(e)}>

              {fields.map((field) => (
                <Field
                  t={t}
                  field={field}
                  error={error}
                  key={field.name}
                  formData={formData}
                  setFormData={setFormData}
                  activeField={activeField}
                  setActiveField={setActiveField}
                  passwordVisibility={passwordVisibility}
                  setPasswordVisibility={setPasswordVisibility}
                />
              ))}
              <p>
                {t("Clicking CREATE ACCOUNT means that you are agree to our")}
                <a href="javascript:void(0)">
                  {" "}
                  <strong style={{ whiteSpace: "nowrap" }}>
                    <NavLink to="/terms">{t("TermsOfServices")}</NavLink>
                  </strong>
                </a>
                .
              </p>

              <Field
                key={"code"}
                field={{
                  name: "code",
                  type: "text",
                  labelKey: "Registration RC code",
                  placeholder: "Registration RC code",
                }}
                error={error}
                formData={formData}
                setFormData={setFormData}
                activeField={activeField}
                setActiveField={setActiveField}
                t={t}
              />
              <button type="submit" className="btn login">
                {isLoading && (
                  <Loader
                    style={{ display: "inline-block" }}
                    type="ThreeDots"
                    color="white"
                    height={5}
                    width={30}
                  />
                )}
                {t("CREATE ACCOUNT")}
              </button>
              <p>
                {t("Already have an account?")}{" "}
                <Link to="/signin">

                  <strong style={{ whiteSpace: "nowrap" }}>
                    {t("Sign in.")}
                  </strong>

                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.user.loading,
  isAuthenticated: state.user.isAuthenticated,
  error: state.user.error,
});

export default connect(mapStateToProps, { signup })(SignUp);
