import "./tickets.css";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import { Ecard } from "../childs/ecard/ecard";
import { useDispatch, useSelector } from "react-redux";
import { Slider } from "../childs/slidersection/slider";
import { Tickets } from "../childs/tickets/ticketsection";
import { InstantPrices } from "../childs/instantPrices";
import Footer from "../../../Landing-page/Footer/footer.jsx";
import Navbar from "../../../Landing-page/Navbar/navbar.jsx";
import { Description } from "../childs/description/description";
import { loadCompetitionDetail } from "../../../../../actions/competitions/singleCompetitions";
import { getCompetitions } from "../../../../../actions/landingPageActions.js";
import { useTranslation } from "react-i18next";
import PromoBar from "../../../Landing-page/MainSection/PromoBar.jsx";

const Ticket = () => {
  const [activeKey, setActiveKey] = useState("A");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const { details, competitions } = state.competitions;
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    dispatch(loadCompetitionDetail(id)).then(() => {
      setIsLoaded(true);
    });
    dispatch(getCompetitions());
  }, [id, dispatch]);

  let currentGroupTitle = null;
  let currentIndex = -1;

  Object.keys(competitions).forEach((groupTitle) => {
    if (competitions[groupTitle] && competitions[groupTitle].length > 0) {
      const foundIndex = competitions[groupTitle].findIndex(
        (comp) => comp.id === parseInt(id)
      );
      if (foundIndex !== -1) {
        currentGroupTitle = groupTitle;
        currentIndex = foundIndex;
        return;
      }
    }
  });

  const handleNext = () => {
    if (!currentGroupTitle) return;

    const groupCompetitions = competitions[currentGroupTitle];

    if (currentIndex < groupCompetitions.length - 1) {
      const nextId = groupCompetitions[currentIndex + 1].id;
      history.push(`/competitions/sub-section/${nextId}#timer`);
    } else {
      const groupKeys = Object.keys(competitions);
      const nextGroupIndex = groupKeys.indexOf(currentGroupTitle) + 1;

      if (nextGroupIndex < groupKeys.length) {
        const nextGroupTitle = groupKeys[nextGroupIndex];
        const nextId = competitions[nextGroupTitle][0].id;
        history.push(`/competitions/sub-section/${nextId}#timer`);
      }
    }
  };

  const handlePrevious = () => {
    if (!currentGroupTitle) return;

    const groupCompetitions = competitions[currentGroupTitle];

    if (currentIndex > 0) {
      const prevId = groupCompetitions[currentIndex - 1].id;
      history.push(`/competitions/sub-section/${prevId}#timer`);
    } else {
      const groupKeys = Object.keys(competitions);
      const prevGroupIndex = groupKeys.indexOf(currentGroupTitle) - 1;

      if (prevGroupIndex >= 0) {
        const prevGroupTitle = groupKeys[prevGroupIndex];
        const prevId =
          competitions[prevGroupTitle][competitions[prevGroupTitle].length - 1]
            .id;
        history.push(`/competitions/sub-section/${prevId}#timer`);
      }
    }
  };

  return (
    <div className="main-section">
      <Navbar />
      {Object.keys(details).length !== 0 ? (
        <>
          <div className="container navigation-buttons">
            <button
              onClick={handlePrevious}
              disabled={currentIndex === 0}
              className="navigation-button previous-button"
            >
              &lt;{t("Previous")}
            </button>
            <button
              onClick={handleNext}
              disabled={
                currentIndex === competitions[currentGroupTitle]?.length - 1
              }
              className="navigation-button next-button"
            >
              {t("Next")}&gt;
            </button>
          </div>
          {isLoaded ? (
            <>
              <Slider competition={details} activeKey={activeKey} />
              {details.insta_prizes.length != 0 && (
                <InstantPrices competition={details} />
              )}
              <Tickets
                competition={details}
                setActiveKey={setActiveKey}
                activeKey={activeKey}
              />
              <Description competition={details} />
              <Ecard competition={details} />
            </>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
            </div>
          )}
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
        </div>
      )}
      {isLoaded && <Footer />}
    </div>
  );
};

export default Ticket;
