import React from 'react';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: "ro",
  lng: "ro",
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    tr: {
      translations: require("./locales/tr/translations.json"),
    },
    bu: {
      translations: require("./locales/bu/translations.json"),
    },
    it: {
      translations: require("./locales/it/translations.json"),
    },
    ro: {
      translations: require("./locales/ro/translations.json"),
    },
    mo: {
      translations: require("./locales/mo/translations.json"),
    },
    nl: {
      translations: require("./locales/nl/translations.json"),
    },
    pt: {
      translations: require("./locales/pt/translations.json"),
    },
    fr: {
      translations: require("./locales/fr/translations.json"),
    },
    de: {
      translations: require("./locales/de/translations.json"),
    },
    es: {
      translations: require("./locales/es/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ['en', 'tr', 'bu', 'it', 'ro', 'mo', 'nl','pt', 'fr', 'de', 'es'];

export default i18n;
