import React, { useEffect, useState } from "react";
import "./section-4.css";
import Swiper from "swiper/bundle";
import { useTranslation } from "react-i18next";
import TrustpilotRating from "../TrustpilotRating";
import { useDispatch, useSelector } from "react-redux";
import { getReviews } from "../../../../../actions/competitions/singleCompetitions";

const Section4 = () => {
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const { reviews } = state.competitions;
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      await dispatch(getReviews());

      const swiper = new Swiper(".third-slider", {
        slidesPerView: 3,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });

      return () => {
        if (swiper) swiper.destroy();
      };
    }
    fetchData();
  }, []);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (reviews.count > 0) {
      const total = 5 * reviews.count;
      const sumReviews = reviews.results.reduce(
        (sum, review) => sum + review.rating,
        0
      );
      const getStars = 5 * sumReviews;
      const percentageValue = getStars / total;

      setPercentage(percentageValue);
      // console.log("Percentage:", percentageValue.toFixed(1),"%");
    }
  }, [reviews]);
  return (
    <section className="section-4">
      <h1 style={{ fontWeight: 200 }}>{t("Customer satisfaction")}</h1>
      <div className="row">
        <div className="col-lg-3 p-0">
          <h2>Excellent</h2>
          <div className="stars pb-3 pt-3">
            <TrustpilotRating rating={percentage} />
          </div>
          <p>
            Based on <span>{reviews.count} reviews</span>
          </p>
          <div className="d-flex trustpilot">
            <i className="fas fa-star ml-1 ml-lg-0"></i>
            <h5 className="ml-2 ml-lg-2">Trustpilot</h5>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="third third-slider swiper">
            <div className="swiper-wrapper">
              {reviews?.results?.map((review) => (
                <div key={review.id} className="swiper-slide third clients">
                  <a
                    href="https://www.trustpilot.com/review/paradisecompetitions.ro?utm_medium=trustbox&utm_source=TrustBoxReviewCollector"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="stars">
                      <TrustpilotRating rating={review.rating} />
                    </div>
                    <h2 style={{whiteSpace:"nowrap"}}>{review.title}</h2>
                    <p>{review.review_text}</p>
                    <div className="customer">{review.customer}</div>
                  </a>
                </div>
              ))}
            </div>
            <div className="swiper-button-next swiper-button-white"></div>
            <div className="swiper-button-prev swiper-button-white"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section4;
