import * as types from "../actions/types";

const initialState = {
  user: null,
  error: null,
  loading: false,
  userTickets: [],
  isSuccess: false,
  userTransactions: [],
  isAuthenticated: null,
  payoutTransactions: [],
  userAffiliateInfo: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.AUTH_REQUEST_START:
      return {
        ...state,
        error: null,
        loading: true,
        isSuccess: false,
      };
    case types.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        isAuthenticated: true,
      };
    case types.AUTHENTICATION_FAIL:
      localStorage.removeItem("access");
      return {
        ...state,
        error: null,
        loading: false,
        isSuccess: false,
        isAuthenticated: false,
      };
    case types.LOGIN_SUCCESS:
    case types.GOOGLE_AUTH_SUCCESS:
    case types.FACEBOOK_AUTH_SUCCESS:
      localStorage.setItem("access", payload.access);
      localStorage.setItem("refresh", payload.refresh);
      return {
        ...state,
        error: null,
        loading: false,
        isAuthenticated: true,
      };
    case types.SIGNUP_SUCCESS:
      localStorage.setItem("access", payload.token_pair.access);
      localStorage.setItem("refresh", payload.token_pair.refresh);
      return {
        ...state,
        error: null,
        loading: false,
        isSuccess: true,
        isAuthenticated: true,
      };
    case types.USER_LOADED_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
        isAuthenticated: true,
      };
    case types.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        error: null,
        user: payload,
        loading: false,
        isAuthenticated: true,
      };
    case types.UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        isAuthenticated: true,
      };
    case types.LOGOUT:
    case types.LOGIN_FAIL:
    case types.SIGNUP_FAIL:
    case types.USER_LOADED_FAIL:
    case types.GOOGLE_AUTH_FAIL:
    case types.FACEBOOK_AUTH_FAIL:
    case types.PASSWORD_RESET_FAIL:
    case types.PASSWORD_RESET_CONFIRM_FAIL:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return {
        ...state,
        user: null,
        loading: false,
        isSuccess: false,
        error: action.error,
        isAuthenticated: false,
      };
    case types.PASSWORD_RESET_SUCCESS:
    case types.PASSWORD_RESET_CONFIRM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isSuccess: true,
      };
    case types.GET_USER_TICKETS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        isSuccess: false,
        userTickets: payload,
      };
    case types.GET_USER_TICKETS_FAIL:
      return {
        ...state,
        error: null,
        loading: false,
        userTickets: [],
        isSuccess: false,
      };
    case types.GET_USER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        isSuccess: false,
        userTransactions: payload,
      };
    case types.GET_USER_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: null,
        loading: false,
        isSuccess: false,
        userTransactions: [],
      };
    case types.GET_USER_PAYOUT_TRANSACTION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        isSuccess: false,
        payoutTransactions: payload,
      };
    case types.GET_USER_AFFILIATE_INFO_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        isSuccess: false,
        userAffiliateInfo: payload,
      };
    case types.CLEAR_ERROR: 
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
