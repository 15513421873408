import * as types from "../actions/types";

const initialState = {
  error: null,
  loading: false,
  newsLetterSuccess: false,
  giveaway: {
    total_giveaway: 0,
    total_winners: 0,
  },
  content : [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.COMMON_REQUEST_START:
    case types.CONTENT_REQUEST_START:
    case types.TOTAL_GIVE_AWAY_REQUEST_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case types.NEWSLETTER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        newsLetterSuccess: true,
      };
    case types.NEWSLETTER_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        error: null,
        newsLetterSuccess: false,
      };
    case types.GET_TOTAL_GIVE_AWAY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        giveaway: payload,
      };
    case types.GET_CONTENT_SUCCESS:
        return {
          ...state,
          error: null,
          loading: false,
          content: payload,
        };
    case types.GET_CONTENT_FAIL:
    case types.GET_TOTAL_GIVE_AWAY_FAIL:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}
