import { useTranslation } from "react-i18next";
import React from "react";
import Logo from "../../../../assets/logo.png";
import { NavLink, Link } from "react-router-dom";
import England from "../../../../assets/england.png";
import Bar from "../../../pages/Landing-page/Navbar/navbar";
const Navbar = ({ user, cart }) => {
  const { i18n, t } = useTranslation();

  return (
    <Bar navColor="#D3D3D3" bgColor="#e4e4e4" textColor="black" iconColor="black"></Bar>
    // <header className="shoopingnavbar navbar navbar-expand-lg navbar-light header">
    //    <NavLink to="/" className="m-0 p-0 d-flex ml-5 figure NavLink heading">
    //     <img className="img-fluid mt-0" src={Logo} alt="logo" />
    //     <h1 className="figcaption">Paradise Competitions</h1>
    //   </NavLink>
    //   <button
    //     className="navbar-toggler"
    //     type="button"
    //     data-toggle="collapse"
    //     data-target="#navbarSupportedContent"
    //     aria-controls="navbarSupportedContent"
    //     aria-expanded="false"
    //     aria-label="Toggle navigation"
    //   >
    //     <span className="navbar-toggler-icon"></span>
    //   </button>

    //   <nav className="collapse navbar-collapse" id="navbarSupportedContent">
    //     <ul className="navbar-nav pb-5 pb-lg-0 pt-1">
    //       <li className="nav-item">
    //         <h2>
    //           <b>{t("Welcome")}</b>
    //         </h2>
    //         <h1>
    //           {user &&
    //             user.username.first_name.charAt(0).toUpperCase() +
    //               user.username.first_name.slice(1)}
    //         </h1>
    //       </li>
    //       <li className="nav-item">
    //         <h2>
    //           <b>Your</b>
    //         </h2>
    //         <Link to="/myaccount/acountdetail" style={{ color: "black" }}>
    //           <h1>{t("My Account")}</h1>
    //         </Link>
    //       </li>
    //       <li className="nav-item dustbinn">
    //         <span style={{ fontWeight: "400" }}>{cart && cart.length}</span>
    //         <i className="fas fa-shopping-cart"></i>
    //       </li>
    //       <button className="england">
    //         <img className="img-fluid" src={England} alt="" /> EN
    //       </button>
    //     </ul>
    //   </nav> 
    //  </header>
  );
};

export default Navbar;
