import React from "react";
import "./style.css";
import "./i18n/config";
import store from "./store";
import App from "./app/app.jsx";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createHistory from "history/createBrowserHistory";
import { GoogleOAuthProvider } from '@react-oauth/google'; 
import { BrowserRouter as Router } from "react-router-dom";
import { GOOGLE_CLIENT_ID } from "./constant/Constant.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
export default createHistory();



ReactDOM.render(
<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
</GoogleOAuthProvider>,
  document.getElementById("root")
);
