export function updateItem(cartItems, item) {
  const index = !item.is_ticket
    ? cartItems.findIndex((x) => x.gift === item.gift)
    : -1;
  
  if (index === -1) return [...cartItems, item];
  if (item.is_dec) {
    cartItems[index].quantity = cartItems[index].quantity - 1;
    if (cartItems[index].quantity === 0) {
      cartItems.splice(index, 1);
    }
  } else {
    cartItems[index].quantity = cartItems[index].quantity + 1;
  }
  return cartItems;
}

export function deleteItem(cartItems, item) {
  cartItems = cartItems.filter((x) => x.id !== item.id);
  return cartItems;
}
