import "./Order.css";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OrderNav from "./childs/OrderNav/ordernav";
import React, { useState, useEffect } from "react";
import { SuccessToast } from "../../components/toaster/toast";
import PaymentInvoice from "../../components/invoices/paymentInvoice";
import { getUserAffiliateInfo } from "../../actions/user/profileActions";
import { FullScreenLoading } from "../../components/loading/FullScreenLoading";
import PaymentConfirmation from "../../components/invoices/paymentConfirmation";
import OrderPageBodyComponent from "./childs/OrderPageBodyComponent/OrderPageBodyComponent";

const Order = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const user = state.user.user;
  const userAffiliateInfo = state.user.userAffiliateInfo;
  const { loading, discounts, cart } = state.cart;
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { success, session_id } = queryString.parse(location.search);

  // Here is PDF File as Blob which will send to Server using formdata
  const [paymentInvoicePDFData, setPaymentInvoicePDFData] = useState(null);
  const [paymentConfirmInvoicePDFData, setPaymentConfirmInvoicePDFData] =
    useState(null);

  const order_data = {
    id: 83,
    order_items: [
      {
        id: 164,
        title: "Brown Suit",
        ticket_title: null,
        ecard_url: null,
        image:
          "https://api.paradisecompetitions.ro/media/competitions/R014-Henk_Helmantel.jpg",
        is_ticket: false,
        quantity: 1,
        price: "12.00",
      },
      {
        id: 165,
        title: "4: ferrari",
        ticket_title: "B01",
        ecard_url: "http://localhost:8000/media/videos/cookie_001_3gmGBTQ.mp4",
        image:
          "https://api.paradisecompetitions.ro/media/competitions/R014-Henk_Helmantel.jpg",
        is_ticket: true,
        quantity: 1,
        price: "10.15",
      },
      {
        id: 166,
        title: "3: Bougati",
        ticket_title: "A12",
        ecard_url: "http://localhost:8000/media/videos/cookie_001_3gmGBTQ.mp4",
        image:
          "https://api.paradisecompetitions.ro/media/competitions/R014-Henk_Helmantel.jpg",
        is_ticket: true,
        quantity: 1,
        price: "12.00",
      },
    ],
    order_transaction: {
      id: 41,
      order: 83,
      date_time: "19 Mar, 2023 12:24:34 ",
      amount_paid: "106.15",
      payment_type: "PayPal",
      transaction_id: "Asd38gh5aAAA",
    },
    user_email: "abdullahzulfiqar785@gmail.com",
    user_last_name: "zulfiqar",
    user_first_name: "abdullah",
    total: "106.15",
    ordered_at: "19 Mar, 2023 12:25:36 ",
    phone: "03454545653",
    address: "Jamia islamia road, abdullah house # 31A,",
    town: "rahim yar khan",
    postcode: "64200",
    country: "Pakistan",
    discount: "0",
    include_gift: true,
    user: 9,
  };

  // These 2 functions generate invoice blob
  async function generatePaymentInvoicePdf() {
    const blob = await pdf(<PaymentInvoice order={order_data} />).toBlob();
    setPaymentInvoicePDFData(blob);
    return URL.createObjectURL(blob);
  }
  async function generateConfirmPaymentInvoicePdf() {
    const blob = await pdf(<PaymentConfirmation order={order_data} />).toBlob();
    setPaymentConfirmInvoicePDFData(blob);
    return URL.createObjectURL(blob);
  }

  // These 2 functions download pdf files seperately on click
  async function handlePaymentDownloadClick() {
    const downloadLink = document.createElement("a");
    downloadLink.download = "payment.pdf";
    downloadLink.href = await generatePaymentInvoicePdf();
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  async function handleConfirmPaymentDownloadClick() {
    const downloadLink = document.createElement("a");
    downloadLink.download = "confirm-payment.pdf";
    downloadLink.href = await generateConfirmPaymentInvoicePdf();
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  async function get_pdfs(order_data) {
    const invoice = await pdf(<PaymentInvoice order={order_data} />).toBlob();
    const paymentConfirmation = await pdf(
      <PaymentConfirmation order={order_data} />
    ).toBlob();
    return {
      invoice: invoice,
      paymentConfirmation: paymentConfirmation,
    };
  }
  useEffect(() => {
    dispatch(getUserAffiliateInfo());
  }, [success, session_id]);

  if (success === "true" && session_id) {
    SuccessToast(
      "Your order has been placed and you will recieve the confirmation email shortly."
    );
    SuccessToast("Your Invoice is sent to your registered email.");
    return <Redirect to="/myaccount/tickets/activetickets/#activetickets" />;
  }
  return (
    <div className="order-body">
      {/* <button
        className="btn btn-primary m-2"
        onClick={handlePaymentDownloadClick}
      >
        Download Payment PDF
      </button>
      <button
        className="btn btn-primary m-2"
        onClick={handleConfirmPaymentDownloadClick}
      >
        Download Confirm Payment PDF
      </button> */}

      {user && <OrderNav user={user} />}
      {cart.length < 1 ? (
        <section
          className="main-section"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <h1>{t("No items to Order. Please add some in your cart.")}</h1>
        </section>
      ) : (
        <>
          {cart && user && (
            <OrderPageBodyComponent
              user={user}
              cart={cart}
              discounts={discounts}
              cartLoading={loading}
              userAffiliateInfo={userAffiliateInfo}
            />
          )}
        </>
      )}
      <FullScreenLoading title="Please wait..." show={paymentLoading} />
    </div>
  );
};

export default Order;
