import "./hero-section.css";
import Swiper from "swiper/bundle";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import { getHeroPrizeCompetitions } from "../../../../actions/landingPageActions";
import { Link } from "react-router-dom";

const HeroSection = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const { loading, heroCompetitions } = state.competitions;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      await dispatch(getHeroPrizeCompetitions());
      new Swiper(".hero.swiper", {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 800,
        simulateTouch: false,
        autoplay: {
          delay: 4500,
        },
        pagination: {
          el: ".hero.swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".hero.swiper-button-next",
          prevEl: ".hero.swiper-button-prev",
        },
      });
    }
    fetchData();
  }, []);

  return (
    <section className="h-hero-section">
      <div>
        <h1>{t("Hero prizes")} !</h1>
        {loading && (
          <section style={{ display: "flex", justifyContent: "center" }}>
            <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
          </section>
        )}
        <div className="hero swiper-pagination"></div>
        <div className="hero swiper">
          <div className="hero swiper-wrapper">
            {heroCompetitions &&
              heroCompetitions.map((obj, i) => (
                <Link
                  to={`/competitions/sub-section/${obj.id}#timer`}
                  key={i}
                  className="hero swiper-slide"
                >
                  <HashLink
                    smooth
                    className="items-link hero swiper-slide"
                    to={`/competitions/sub-section/${obj.id}#timer`}
                    scroll={(el) => {
                      const offset = isMobile ? 120 : 200;
                      window.scrollTo({
                        top: el.offsetTop - offset,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <img src={obj.image_url} />
                    <button type="submit">{t("Enter Now")}</button>
                  </HashLink>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
