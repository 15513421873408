import React, { useEffect } from "react";
import queryString from "query-string";
import { Redirect } from "react-router";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { REQUEST_URL } from "../../../../constant/Constant";
import { GOOGLE_AUTH_SUCCESS } from "../../../../actions/types";

const Google = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const values = queryString.parse(location.search);
    if (values.access && values.refresh) {
      dispatch({
        type: GOOGLE_AUTH_SUCCESS,
        payload: values,
      });
    }
  }, [location]);
  return (
    <>
      <Redirect to="/" />
    </>
  );
};

export default Google;

export const continueWithGoogle = () => {
  window.location.replace(`${REQUEST_URL}/api/accounts/google?flag=google`);
};
