import React, { useState } from "react";
import axios from "axios";
import "./account_activate.css";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "../../../Landing-page/Navbar/navbar.jsx";
import { reset_password_confirm } from "../../../../../actions/user/auth";
import { REQUEST_URL } from "../../../../../constant/Constant";

const AccountActivate = ({ match, reset_password, history }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [formError, setFormError] = useState({});
  let { uid, token } = useParams();
  const { new_password, re_new_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    axios
      .post(REQUEST_URL + `/auth/account/activate`, {
        uid: uid,
        token: token,
        new_password: formData.new_password,
        confirm_password: formData.confirm_password,
      })
      .then(() => {
        history.replace("/");
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setFormError(error.response.data);
        }
      });
    await reset_password_confirm(new_password, re_new_password);
    setRequestSent(true);
  };
  // if (activationSuccess) {
  // 	return <Redirect to="/" />;
  // }
  return (
    <>
      <Navbar />
      <div className="logIn">
        <div className="form-modal">
          <div className="form-toggle">
            <button id="login-toggle" disabled>
              Set Password
            </button>
          </div>

          <div id="login-form">
            <form onSubmit={(e) => onSubmit(e)}>
              <span className="error">
                {formError && formError.token ? formError.token[0] : null}
              </span>
              <input
                type="password"
                placeholder="new password"
                name="new_password"
                value={new_password}
                minLength="6"
                onChange={(e) => onChange(e)}
              />
              <span className="error">
                {formError && formError.new_password
                  ? formError.new_password[0]
                  : null}
              </span>
              <input
                type="password"
                placeholder="confirm new password"
                name="confirm_password"
                value={re_new_password}
                minLength="6"
                onChange={(e) => onChange(e)}
              />
              <span className="error">
                {formError && formError.new_password
                  ? formError.confirm_password[0]
                  : null}
              </span>
              <button type="submit" className="btn login">
                {/* {isLoading ? (
									<Loader
										style={{ display: 'inline-block' }}
										type="ThreeDots"
										color="white"
										height={5}
										width={30}
									/>
								) : (
									''
								)} */}
                Reset
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { reset_password_confirm })(AccountActivate);
