import React from "react";
import "./style.css";
const TrustBox = () => {
  const ref = React.useRef();
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="56278e9abfbbba0bdcd568bc"
      data-businessunit-id="65ddf77998bfb3088b8d4e45"
      data-style-height="55px"
      data-style-width="100%"
      data-theme="dark"
    >
      <a
        href="https://uk.trustpilot.com/review/paradisecompetitions.ro"
        target="_blank"
        rel="noopener"
      >
        {" "}
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
