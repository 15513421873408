import React from "react";
import "../AffiliateIncome.css";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { connectWithPaypal } from "../../../../../../actions/user/profileActions";

const AACComponent = window.paypal.PayoutsAAC.driver("react", {
  React,
  ReactDOM,
});

export const PaypalButton = ({ userAffiliateInfo }) => {
  const dispatch = useDispatch();
  console.log(
    !(userAffiliateInfo && userAffiliateInfo.paypal_email),
    "--------"
  );
  return (
    <div>
      <div className="widthdraw-btns">
        {!(userAffiliateInfo && userAffiliateInfo.paypal_email) ? (
          <div style={{ width: "300px" }}>
            <AACComponent
              clientId={{
                sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID,
              }}
              env="sandbox"
              merchantId={process.env.REACT_APP_PAYPAL_MERCHANT_ID}
              pageType="signup"
              onLogin={(e) => dispatch(connectWithPaypal(e))}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
