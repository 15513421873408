import React from "react";
import "./optionBar.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { logout } from "../../../actions/user/auth";

const OptionBar = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const isActive = (match, location) => {
    if (location.pathname.includes("/myaccount/tickets")) {
      return true;
    }
    return false;
  };

  return (
    <aside className="my-acount-sideNav">
      <ul>
        <NavLink
          className="my-acount-sideNav-links"
          activeClassName="my-acount-sideNav-activelink"
          to="/myaccount/acountdetail"
        >
          <li className="sidenav-items">
            <i className="fas fa-user-circle"></i> {t("Account Details")}
          </li>
        </NavLink>
        <NavLink
          className="my-acount-sideNav-links"
          activeClassName="my-acount-sideNav-activelink"
          isActive={isActive}
          to="/myaccount/tickets/activetickets"
        >
          <li>
            <i className="fas fa-ticket-alt fa-alt"></i> {t("My Tickets")}
          </li>
        </NavLink>
        <NavLink
          className="my-acount-sideNav-links"
          activeClassName="my-acount-sideNav-activelink"
          to="/myaccount/transaction"
        >
          <li>
            <i className="fas fa-coins"></i> {t("My Transactions")}
          </li>
        </NavLink>
        <NavLink
          activeClassName="my-acount-sideNav-activelink"
          className="my-acount-sideNav-links"
          to="/myaccount/wallet"
        >
          <li>
            <i className="fas fa-wallet fa-alt"></i> My Wallet
          </li>
        </NavLink>
        <NavLink
          className="my-acount-sideNav-links logout"
          to="/"
          onClick={() => dispatch(logout())}
        >
          <li>
            <i className="fas fa-sign-out-alt"></i> Logout
          </li>
        </NavLink>
      </ul>
    </aside>
  );
};

export default OptionBar;
