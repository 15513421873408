import React from "react";
import { withTranslation } from "react-i18next";
import "./how-to-play.css";

import { NavLink } from "react-router-dom";

import Tree from "../../../assets/logo.png";

import Footer from "../Landing-page/Footer/footer.jsx";
import Navbar from "../Landing-page/Navbar/navbar.jsx";

class HowToPlay extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Navbar />
        <section className="how-to-play">
          <div>
            {/* first */}
            <div style={{ textAlign: "center" }}>
              <h5
                className="pb-4"
                style={{ color: "lightgray", fontWeight: "900" }}
              >
                <span style={{ fontWeight: 500 }}>Win</span> BIG
                <span style={{ fontWeight: 500 }}> for less</span>
              </h5>
              <p>{t("Win BIG for less_1")}</p>
              <p>{t("Win BIG for less_2")}</p>
              <p>{t("Win BIG for less_3")}</p>
            </div>
            {/* Second */}
            <div>
              <h5 className="pt-4 pb-4">{t("q6")}</h5>
              <p> Step1 - {t("q6_step1")}</p>
              <p> Step2 - {t("q6_step2")}</p>
              <p> Step3 - {t("q6_step3")}</p>
              <p>&nbsp;&nbsp;&emsp;&emsp;&emsp;{t("q6_step4")}</p>
            </div>
            {/* Third */}
            <div>
              <h5 className="pt-0 pb-4">{t("q7")}</h5>
              <p> {t("answer7_1")}</p>
              <p> {t("answer7_2")}</p>
            </div>
            {/* Fourth */}
            <div>
              <h5 className="pt-0 pb-4">{t("q8")}</h5>
              <p> {t("answer8_1")}</p>
              <p>{t("answer8_2")}</p>
            </div>
            {/* Fifth */}
            <div>
              <h5 className="pt-0 pb-4">{t("q9")}</h5>
              <p> {t("answer9_1")}</p>
              <p>{t("answer9_2")}</p>
              <p>{t("answer9_3")}</p>
              <p>{t("answer9_4")}</p>
            </div>
            <div style={{ textAlign: "center", paddingTop: "40px" }}>
              <p>{t("last_line1")}</p>
              <p>{t("last_line2")}</p>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default withTranslation()(HowToPlay);
