import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

const Total = ({ cart, discounts }) => {
  const { i18n, t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let discount = discounts
    .reduce((acc, item) => acc + item.amount, 0)
    .toFixed(2);

  let subtotal = cart
    .reduce((acc, item) => acc + Number(item.price) * item.quantity, 0)
    .toFixed(2);

  let total = subtotal - subtotal * (discount / 100);
  return (
    <div className="last-div-cont">
      <div>
        {/* <button className="submit-co-code">Submit Coupon Code</button> */}
        <button></button>
        <div>
          <h1>
            Subtotal:&nbsp;
            <span>Lei {subtotal}</span>
          </h1>
          <h1>
            {t("Discount")}:&nbsp;
            <span>{discount}%</span>
          </h1>
          <h1>
            TOTAL:&nbsp;
            <span>Lei {total.toFixed(2)}</span>
          </h1>
          <HashLink
            smooth
            to="/#competitionSect"
            scroll={(el) =>
              window.scrollTo({
                top: el.offsetTop - (isMobile ? 0 : 150),
                behavior: "smooth",
              })
            }
          >
            <button className="cont-shop-btn">
              <i className="fas fa-chevron-left"></i>
              {t("CONTINUE SHOPPING")}
            </button>
          </HashLink>
          <Link to="/order">
            <button className="cont-shop-btn">
              <i className="fas fa-chevron-right"></i>
              {t("PROCEED TO CHECKOUT")}
            </button>
          </Link>
          {/* <NavLink
            activeClassName="activeNavBtn"
            className="NavLink"
            to="/order"
          >
            <button className="pro-checkout-btn" to="/order">
              <i className="fas fa-chevron-right"></i>
              {t("PROCEED TO CHECKOUT")}
            </button>
          </NavLink> */}
        </div>
      </div>
    </div>
  );
};

export default Total;
