import "./signin.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { login, clearError } from "../../../../actions/user/auth";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../Landing-page/Navbar/navbar.jsx";
import Pic1 from "../../../../assets/053 png google G.png";
import { socialAuthLogin } from "../../../../actions/user/auth";
import { FACEBOOK_APP_ID } from "../../../../constant/Constant.js";
import { GOOGLE_PROFILE_REQUEST_URL } from "../../../../constant/Constant.js";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Field } from "../../../../components/fields.jsx";

const SignIn = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [activeField, setActiveField] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const { error, loading, isAuthenticated } = state.user;

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(formData));
  };

  const faceBookCallBack = (response) => {
    dispatch(
      socialAuthLogin({
        provider: "FACEBOOK",
        email: response.email,
        last_name: response.last_name,
        first_name: response.first_name,
        authToken: response?.accessToken,
      })
    );
  };

  const signUpWithGoogle = useGoogleLogin({
    onSuccess: (response) => {
      axios
        .get(GOOGLE_PROFILE_REQUEST_URL, {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        })
        .then((res) => {
          dispatch(
            socialAuthLogin({
              provider: "GOOGLE",
              email: res.data.email,
              last_name: res.data.name.split(" ")[1],
              first_name: res.data.name.split(" ")[0],
              authToken: response.access_token,
            })
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    flow: "implicit",
    scope: "profile email",
  });

  if (isAuthenticated) return <Redirect to="/" />;

  const fields = [
    {
      name: "email",
      labelKey: "Email address",
      placeholder: "Enter your email",
      type: "email",
    },
    {
      name: "password",
      labelKey: "Password",
      placeholder: "Enter your password",
      type: passwordVisibility ? "text" : "password",
    },
  ];
  return (
    <>
      <Navbar />
      <div className="logIn">
        <div className="form-modal">
          <div className="form-toggle">
            <button id="login-toggle" disabled>
              CLIENT lOGIN
            </button>
          </div>

          <div id="login-form">
            <form onSubmit={(e) => onSubmit(e)}>
              {/* <div className="btnGroup">
                <button
                  type="button"
                  className="btn box-sd-effect"
                  onClick={signUpWithGoogle}
                >
                  <img src={Pic1} alt="" /> Google
                </button>
                <FacebookLogin
                  appId={FACEBOOK_APP_ID}
                  autoLoad={true}
                  fields="first_name,last_name,email"
                  callback={faceBookCallBack}
                  isMobile={false}
                  disableMobileRedirect={true}
                  render={(renderProps) => (
                    <button
                      type="button"
                      className="btn box-sd-effect"
                      onClick={renderProps.onClick}
                    >
                      <i className="fab fa-facebook-square"></i> Facebook
                    </button>
                  )}
                />
              </div> */}
             
             {fields.map((field) => (
                <Field
                  t={t}
                  field={field}
                  error={error}
                  key={field.name}
                  formData={formData}
                  setFormData={setFormData}
                  activeField={activeField}
                  setActiveField={setActiveField}
                  passwordVisibility={passwordVisibility}
                  setPasswordVisibility={setPasswordVisibility}
                />
              ))}

              <button type="submit" className="btn login">
                {loading ? (
                  <Loader
                    style={{ display: "inline-block" }}
                    type="ThreeDots"
                    color="white"
                    height={5}
                    width={30}
                  />
                ) : (
                  ""
                )}
                {t("Login")}
              </button>
              <p>
                {t("Forgot Password?")}{" "}
                <Link to="reset">
                  <strong onClick={()=>dispatch(clearError())} style={{ fontSize: "17px",whiteSpace:"nowrap" }}>
                    {t("Reset Here")}
                  </strong>
                </Link>
              </p>
              <p>
                {t("Don't have an account?")}{" "}
                <Link to="/signup">
                  <strong onClick={()=>dispatch(clearError())} style={{ fontSize: "17px" }}>{t("SignUp")}</strong>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
