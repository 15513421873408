import axios from "axios";
import * as types from "../types";
import jwt_decode from "jwt-decode";
import { getTokenIncludedConfig } from "../common";
import { REQUEST_URL } from "../../constant/Constant";
import { ErrorToast, SuccessToast } from "../../components/toaster/toast";
import { getCartData } from "../cart/cart";

export const update_profile = (userProfile) => async (dispatch) => {
  dispatch({
    type: types.AUTH_REQUEST_START,
  });
  const body = JSON.stringify({ profile: userProfile });
  const decoded = jwt_decode(localStorage.getItem("access"));
  try {
    const res = await axios.put(
      REQUEST_URL + `/api/profile/update/${decoded.user_id}/`,
      body,
      getTokenIncludedConfig()
    );
    dispatch({
      type: types.UPDATE_USER_PROFILE_SUCCESS,
      payload: res.data,
    });
    SuccessToast(`🙌 💯 Profile info updated successfully.`);
  } catch (error) {
    dispatch({
      error: error.response,
      type: types.UPDATE_USER_PROFILE_FAIL,
    });
  }
};

export const load_user = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const decoded = jwt_decode(localStorage.getItem("access"));
    try {
      const res = await axios.get(
        REQUEST_URL + `/api/profile/user/${decoded.user_id}/`,
        getTokenIncludedConfig()
      );
      dispatch({
        type: types.USER_LOADED_SUCCESS,
        payload: res.data,
      });
      dispatch(getCartData())
    } catch (err) {
      dispatch({
        type: types.USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: types.USER_LOADED_FAIL,
    });
  }
};
