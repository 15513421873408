import "./gift-shop.css";
import ECard from "./childs/ecard";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import React, { useEffect, useState } from "react";
import Footer from "../Landing-page/Footer/footer";
import { addToCart } from "../../../actions/cart/cart";
import Navbar from "../Landing-page/Navbar/navbar.jsx";
import { useSelector, useDispatch } from "react-redux";
import { loadGiftShop } from "../../../actions/giftshop/giftshop";

const GiftShop = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const { loading, items } = state.giftshop;
  const isAuthenticated = state.user.isAuthenticated;
  const [modelShow, setModelShow] = useState(false);
  const [modelText, setModelText] = useState(false);

  useEffect(() => {
    dispatch(loadGiftShop());
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#giftPage") {
      const element = document.getElementById("giftPage");
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - 120;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <section className="page-giftshopCon" id="giftPage">
        <ECard history={history} />
        {!loading ? (
          items &&
          items.map((obj, index) => {
            return (
              <>
                <div className="page-giftshop" key={index} style={{padding:"10px 0px 10px 0px"}}>
                  <div>
                    <figure
                      style={{
                        backgroundImage: `url(${obj.image})`,
                      }}
                    ></figure>
                  </div>
                  <div>
                    <h2>{obj.title}</h2>
                    <p className="mt-2 expand-content">
                      {ReactHtmlParser(obj.description)}
                    </p>
                    <p
                      onClick={() => {
                        setModelShow(true);
                        setModelText(obj.description);
                      }}
                      style={{
                        fontSize: "2rem",
                        width: "10px",
                        border: "1.3px solid white",
                        borderRadius: "50%",
                        padding: "3px 26px 0px 7px",
                        cursor: "pointer",
                      }}
                    >
                      <Plus/>
                    </p>
                    <h1>Lei {obj.price} </h1>
                    <button
                    className="addCart"
                      key={index}
                      value={obj.id}
                      onClick={(e) => {
                        if (isAuthenticated) {
                          dispatch(
                            addToCart(false, {
                              gift: e.target.value,
                            })
                          );
                        } else {
                          return history.replace("/signin");
                        }
                      }}
                    >
                      {t("Add to Cart")}
                    </button>
                    {/* <div className="page-soldout">Discounted via our Competitions</div> */}
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
          </div>
        )}
        <MyVerticallyCenteredModal
          text={modelText}
          show={modelShow}
          onHide={() => setModelShow(false)}
        />
      </section>
      <style>
        {`
      .gift-shop-modal .modal-content ::-webkit-scrollbar-thumb {
          background: rgb(53, 53, 53) !important;
           border: 3px solid gray !important; 
            }

      .gift-shop-modal .modal-content ::-webkit-scrollbar-track {
           background: gray !important;
            }

      .gift-shop-modal .modal-content::-webkit-scrollbar {
           width: 5px !important;
           }
      `}
      </style>
      <Footer />
    </>
  );
};

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="gift-shop-modal"
    >
      <Modal.Body>
        <div className="mt-2" style={{fontSize:"14px"}}>
          {ReactHtmlParser(props.text)}
          <div style={{display:"flex", justifyContent:"flex-end"}}>
            <p
              onClick={props.onHide}
              style={{
                fontSize: "2rem",
                width: "10px",
                border: "1.3px solid white",
                borderRadius: "50%",
                padding: "4px 28px 0px 7px",
                cursor: "pointer",
              }}
            >
              <Minus/>
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default GiftShop;


const Plus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="20"
    height="18"
    viewBox="0 0 24 24"
    strokeWidth={1}
  >
    <path
      fill="currentColor"
      d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
    ></path>
  </svg>
);

const Minus = () => (
  <svg
    width={22}
    height={20}
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M216,136H40a8,8,0,0,1,0-16H216a8,8,0,0,1,0,16Z"
      fill="currentColor"
    />
  </svg>
);