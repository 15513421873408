import React from "react";
import "../AffiliateIncome.css";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { withDrawBalance } from "../../../../../../actions/user/profileActions";

export const AffiliateInfo = ({ userAffiliateInfo }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <section className="transection-section">
        <div className="overflower-video">
        <div className="row transection-head transection-row-col m-0">
        <div className="col col-lg">
            <h1>{t("Site Credit")}</h1>
          </div>
        </div>
        <div   className="row transection-head transection-col1 m-0"
          style={{ color: "black" ,}} >
        <div className="col col-lg">
            <h1>Lei {userAffiliateInfo && userAffiliateInfo.site_cash}</h1>
          </div>
        </div>
        </div>
      <h3 className="paypal-connected" style={{ color: "rgb(210,210,210)",margin:"10px 0px"}}>{t("Site Credit won can be used to pay on the website.")}</h3>
      <div className="overflower-video">
       
        <div className="row transection-head transection-row m-0">
          <div className="col col-lg">
            <h1>{t("My Followers")}</h1>
          </div>
          <div className="col col-lg">
            <h1>{t("My Earnings")}</h1>
          </div>
         
        </div>
        <div
          className="row transection-head transection-col m-0"
          style={{ color: "black" ,}}
        >
          <div className="col col-lg" >
            <h1>{userAffiliateInfo && userAffiliateInfo.affiliates}</h1>
          </div>
          <div className="col col-lg">
            <h1>Lei {userAffiliateInfo && userAffiliateInfo.balance}</h1>
          </div>
         
        </div>
        </div>
      </section>
      <div className="d-flex flex-col justify-content-center">
      <div className="detail" style={{ position: "relative", marginBottom: "0px",marginTop:"22px" }}>
          <h3 className="text-center">
            {t("According to our Affilate Agreement")} <br/>
            {t("as our Affilate you earn 2% of your Followers transactions.")} <br/>
            {t("You can withdraw Earnings when you have 5 Followers.")}
          </h3>
          {/* <h3 className="warning">{t("You can withdraw if you have 5 leads.")}</h3> */}
          <h3 className="paypal-connected" style={{ color: "rgb(210,210,210)"}}>{t("Withdraw minimum 100 Lei.")}</h3>
      </div>
      
      <div style={{ color: "#ffd452" }}>
        <div className="widthdraw-btns">
          <button
            onClick={() => {
              setLoading(true);
              dispatch(withDrawBalance(setLoading));
            }}
            className="withdraw-btn"
          >
            {loading ? (
              <Loader
                style={{ display: "inline-block" }}
                type="ThreeDots"
                color="white"
                height={5}
                width={30}
              />
            ) : (
              ""
            )}
            {t("Withdraw from My Earnings")}
          </button>
        </div>
      </div>

      </div>
    </>
  );
};
