import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { doLuckyDip } from "../../actions/competitions/singleCompetitions";
import Loader from "react-loader-spinner";

function DisplayColumns({ competitions, offsetTop }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const isAuthenticated = state.user.isAuthenticated;
  const [luckyDipCount, setLuckyDipCount] = useState(0);
  const [luckyDip, setLuckyDip] = useState(false);
  const history = useHistory();
  const handleIncrement = (id, limit) => {
    setLuckyDipCount((prevState) => {
      const currentCount = prevState[id] || 0;
      if (currentCount < limit) {
        return {
          ...prevState,
          [id]: currentCount + 1,
        };
      }
      return prevState;
    });
  };
  const handleDecrement = (id) => {
    setLuckyDipCount((prevState) => ({
      ...prevState,
      [id]: prevState[id] && prevState[id] > 0 ? prevState[id] - 1 : 0,
    }));
  };

  const handleLuckyDip = (id) => {
    setLuckyDip((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const callBack = (id) => {
    setLuckyDip((prevState) => ({
      ...prevState,
      [id]: false,
    }));
    setLuckyDipCount(0);
  };
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "15px",
        marginTop: "25px",
      }}
    >
      {competitions?.length > 0 ? (
        competitions.map((item, index) => (
          <div key={index} className="card-container">
            <HashLink
              smooth
              to={`/competitions/sub-section/${item.id}#timer`}
              scroll={(el) =>
                window.scrollTo({
                  top: el.offsetTop - offsetTop,
                  behavior: "smooth",
                })
              }
            >
              <img src={item?.image_url} alt="Shoes" className="card-image" />
            </HashLink>
            <h1 className="card-heading">{item.title}</h1>
            <div className="card-quantity">
              <button
                onClick={() => handleDecrement(item.id)}
                disabled={luckyDipCount[item.id] === 0}
              >
                <Minus />
              </button>
              <h2>{luckyDipCount[item.id] ?? 0}</h2>
              <button
                onClick={() => handleIncrement(item.id, item.buying_limit)}
                disabled={luckyDipCount[item.id] >= item.buying_limit}
              >
                <Plus />
              </button>
            </div>
            <button
              onClick={() => {
                if (isAuthenticated) {
                  dispatch(
                    doLuckyDip(
                      item.id,
                      null,
                      () => callBack(item.id),
                      luckyDipCount[item.id] > 0 ? luckyDipCount[item.id] : 1
                    )
                  );
                  handleLuckyDip(item.id);
                } else {
                  return history.replace("/signin");
                }
              }}
              disabled={luckyDip[item.id] ?? false}
              className="card-button"
            >
              {luckyDip[item.id] && (
                <Loader
                  style={{ display: "inline-block", color: "black" }}
                  type="ThreeDots"
                  color="white"
                  height={5}
                  width={30}
                />
              )}
              Lucky Dip
            </button>
            <span style={{ height: "42px" }}>
              {item.tickets_length.sold ===
              item.tickets_length.total_tickets ? (
                <></>
              ) : item.discount_active ? (
                <span>
                  <span className={"discount-bar card-price"}>
                    Lei {item.first_day_price}
                  </span>
                  <span className="card-price"> Lei {item.discount_price}</span>
                  <h1 className="card-price-text">{item.discount_text}</h1>
                </span>
              ) : (
                <h2>
                  <h1 className="card-price">Lei {item.first_day_price}</h1>
                </h2>
              )}{" "}
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <span
                style={{ color: "gold", fontSize: "11px", fontWeight: "600" }}
              >
                Sold:{" "}
                {(
                  (item.tickets_length.sold /
                    item.tickets_length.total_tickets) *
                  100
                ).toFixed()}
                %
              </span>
              <span
                style={{ color: "gold", fontSize: "11px", fontWeight: "400" }}
              >
                {item.tickets_length.sold}/{item.tickets_length.total_tickets}
              </span>
            </span>

            <ProgressBar
              progress={
                (item.tickets_length.sold / item.tickets_length.total_tickets) *
                100
              }
            />
          </div>
        ))
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default DisplayColumns;

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

const Plus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="20"
    height="18"
    viewBox="0 0 24 24"
    strokeWidth={1}
  >
    <path
      fill="currentColor"
      d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
    ></path>
  </svg>
);

const Minus = () => (
  <svg
    width={20}
    height={18}
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M216,136H40a8,8,0,0,1,0-16H216a8,8,0,0,1,0,16Z"
      fill="currentColor"
    />
  </svg>
);
