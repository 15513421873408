import axios from "axios";
import * as types from "./types";
import { REQUEST_URL } from "../constant/Constant";

export const getContent = () => async (dispatch) => {
  dispatch({
    type: types.CONTENT_REQUEST_START,
  });
  try {
    const res = await axios.get(`${REQUEST_URL}/api/competitions/content/`);
    dispatch({
      type: types.GET_CONTENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: types.GET_CONTENT_FAIL,
    });
  }
};
