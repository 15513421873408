import React from "react";
export const Field = ({
  field,
  formData,
  setFormData,
  setPasswordVisibility,
  passwordVisibility,
  error,
  activeField,
  setActiveField,
  t,
}) => {
  const { name, placeholder, type, labelKey } = field;

  const inputType =
    name === "password" ? (passwordVisibility ? "text" : "password") : type;

  return (
    <div className="password-input">
      {activeField === name && (
        <span style={{ fontSize: "13px", fontWeight: "600" }}>
          {t(labelKey)}
        </span>
      )}
      {error?.data?.[name] && (
        <div style={{ color: "#E0115F", fontSize: "12px", fontWeight: "500" }}>
          {error.data[name].join(" ")}
        </div>
      )}
      <input
        type={inputType}
        placeholder={t(placeholder)}
        name={name}
        value={formData[name]}
        onChange={(e) =>
          setFormData({ ...formData, [e.target.name]: e.target.value })
        }
        onFocus={(e) => setActiveField(e.target.name)}
        onBlur={(e) => !e.target.value && setActiveField("")}
        style={{ borderRadius: "0px" }}
      />
      {name === "password" && (
        <i
          onClick={() => setPasswordVisibility(!passwordVisibility)}
          className={passwordVisibility ? "fa fa-eye" : "fa fa-eye-slash"}
          style={{ cursor: "pointer", marginLeft: "10px" }}
        ></i>
      )}
    </div>
  );
};
