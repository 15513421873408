import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { addToCart, deleteCartItem } from "../../../../actions/cart/cart";

const CartItems = ({ cart }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  return (
    <div className="second-div-cont">
      <div className="shopping-items-cont">
        <div className="order-items-container">
        <div className="items-head">
          <div>
            <h1>{t("ITEMS")}</h1>
          </div>
          <div>
            <h1>{t("QUANTITY")}</h1>
          </div>
          <div>
            <h1>{t("UNIT PRICE")}</h1>
          </div>
          <div>
            <h1>{t("SUBTOTAL")}</h1>
          </div>
        </div>
          {cart.map((obj, i) => {
            return (
              <div className="shopping-items" key={i}>
                <div>
                  <div>
                    <figure
                      className="shoping-items-img"
                      style={{
                        backgroundImage: `url(${obj.image})`,
                      }}
                    ></figure>
                  </div>
                  <div>
                    <h4>
                      {obj.is_ticket
                        ? t("Digital surprise Fortune Cookie with free")
                        : ""}
                    </h4>
                    <h2>
                      {obj.title} {obj.is_ticket ? t("Entrance Ticket") : ""}
                      &nbsp; <span>{obj.ticket_name}</span>
                    </h2>
                  </div>
                </div>
                <div>
                  <h1>
                    {obj.is_ticket ? (
                      ""
                    ) : (
                      <button
                        className="decBtn"
                        value={obj.id}
                        onClick={(e) => {
                          dispatch(
                            addToCart(false, {
                              gift: obj.gift,
                              is_dec: true,
                            })
                          );
                        }}
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    )}
                    {obj.quantity}
                    {obj.is_ticket ? (
                      ""
                    ) : (
                      <button
                        className="incBtn"
                        onClick={(e) => {
                          dispatch(
                            addToCart(false, {
                              gift: obj.gift,
                            })
                          );
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    )}
                  </h1>
                </div>
                <div>
                  <h1>{obj.price}</h1>
                </div>
                <div>
                  <h1>{Number(obj.price) * obj.quantity}</h1>
                </div>
                <div className="del-icon">
                  <button
                    onClick={() => {
                      dispatch(deleteCartItem(obj.id));
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CartItems;
