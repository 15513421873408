import "../../Ticket/tickets.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAlphabetsList, checkToActiveTicket } from "../../common.js";
import { loadTickets } from "../../../../../../actions/competitions/singleCompetitions";
import { addToCart } from "../../../../../../actions/cart/cart";

export const Tickets = ({ competition, setActiveKey, activeKey }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const { tickets, loading } = state.competitions;
  const isAuthenticated = state.user.isAuthenticated;
  const [luckyDipCount, setLuckyDipCount] = useState(0);
  const [alphabetList, setAlphabetList] = useState([]);

  useEffect(() => {
    setAlphabetList(getAlphabetsList(competition.letter_choices));
    ticketsSwitch();
  }, [competition.id]);

  const ticketsSwitch = (alphabet = "A") => {
    setActiveKey(alphabet);
    dispatch(loadTickets(competition.id, alphabet));
  };

  const [seeAll, setSeeAll] = useState(false);
  const sectionRef = useRef(null);
  const seeAllRef = useRef(null);

  const toggleSeeAll = () => {
    const adjustScroll = () => {
      const titlePosition = seeAllRef.current.getBoundingClientRect().top;
      const offset = window.pageYOffset + titlePosition - 300;
      window.scrollTo({ top: offset, behavior: "smooth" });
    };
  
    setSeeAll(!seeAll);
    setTimeout(adjustScroll, 0);
  };
 

  return (
    <div className="tickets" ref={sectionRef}>
      <p className="stickyBar" ref={seeAllRef}>
        <div onClick={toggleSeeAll}>
          <h1 className="ticketsSection">
            {seeAll ? <span>-</span> : <span>+</span>}
            {t("Select your tickets")}
          </h1>
        </div>
        <hr />
      </p>

      <p>
        {t("First click")} {t("Repeat this")}
        <br />
        {t("Our Lucky")}
      </p>
      <Tabs
        id="controlled-tab-example"
        className="alphabets"
        activeKey={activeKey}
        onSelect={(k) => ticketsSwitch(k)}
      >
        {alphabetList.map((object, i) => (
          <Tab eventKey={object} title={object} key={i}>
            <div className={`ticketsNo ${seeAll ? "less" : ""}`}>
              {!loading ? (
                tickets.map((obj, i) => {
                  switch (obj.status) {
                    case 1:
                      return (
                        <button
                          className="ticket"
                          key={obj.id}
                          value={obj.id}
                          onClick={(e) => {
                            if (isAuthenticated) {
                              dispatch(
                                addToCart(true, {
                                  key: activeKey,
                                  ticket: e.target.value,
                                  comp_id: competition.id,
                                })
                              );
                              e.target.classList.add("activeTicket");
                            } else {
                              return history.replace("/signin");
                            }
                          }}
                        >
                          {obj.ticket}
                        </button>
                      );
                      break;
                    case 2:
                      return (
                        <button disabled key={obj.id}>
                          {obj.ticket}
                        </button>
                      );
                      break;
                    case 3:
                      return (
                        <button
                          style={{
                            backgroundColor: checkToActiveTicket(obj.customer),
                          }}
                          key={obj.id}
                        >
                          {obj.ticket}
                        </button>
                      );
                      break;
                  }
                })
              ) : (
                <Loader
                  type="ThreeDots"
                  color="#00FFEA"
                  height={80}
                  width={80}
                />
              )}
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};
