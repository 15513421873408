import React from "react";
import "./ScrollBar.css";
import { useTranslation } from "react-i18next";


const OrderItems = ({ cart }) => {
  const { i18n, t } = useTranslation();

  return (
    <div>
     
      <div className="order-items-container">
      <div className="order-head">
        <div>
          <h1>ITEMS</h1>
        </div>

        <div>
          <h1>SUBTOTAL</h1>
        </div>
      </div>
        {cart &&
          cart.map((obj, i) => (
            <div className="order-items" key={i}>
              <div>
                <div>
                  <figure
                    className="order-items-img"
                    style={{
                      backgroundImage: `url(${obj.image})`,
                    }}
                  >
                    <span>{obj.quantity}</span>
                  </figure>
                </div>
                <div>
                  {obj.is_ticket && <h4>{t("Digital surprise Fortune Cookie with free")}</h4>}
                  <h2>
                    <span>
                      {obj.title} {obj.is_ticket ? t("Entrance Ticket") : ""}
                      &nbsp;
                    </span>{" "}
                    {obj.is_ticket && <span>{obj.ticket_name}</span>}
                  </h2>
                </div>
              </div>

              <div>
                <h1>{Number(obj.price) * obj.quantity}</h1>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OrderItems;
