import * as types from "../actions/types";
import { updateItem, deleteItem } from "./utils";
const initialState = {
  cart: [],
  error: null,
  discounts: [],
  loading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.CART_REQUEST_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case types.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        cart: updateItem(state.cart, payload),
      };
    case types.LUCKY_DIP_ADD_TO_CART_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        cart: [...state.cart, ...payload]
      };
    case types.UPDATE_CART_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        cart: deleteItem(state.cart, payload),
      };
    case types.ADD_TO_CART_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.GET_CART_DATA_SUCCESS:
      return {
        ...state,
        cart: payload,
        loading: false,
      };
    case types.GET_CART_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case types.GET_DISCOUNTS:
      return {
        ...state,
        discounts: payload,
      };
    case types.DELETE_CART_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        cart: deleteItem(state.cart, payload),
      };
    default:
      return state;
  }
}
