import axios from "axios";
import * as types from "../types";
import { REQUEST_URL } from "../../constant/Constant";

export const loadGiftShop = () => async (dispatch) => {
  dispatch({
    type: types.GIFTSHOP_REQUEST_START,
  });
  try {
    const res = await axios.get(`${REQUEST_URL}/api/giftshop/`);
    dispatch({
      type: types.GET_GIFTSHOP_ITEMS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: types.GET_GIFTSHOP_ITEMS_FAIL,
    });
  }
};
