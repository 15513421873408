import React from "react";

const TrustpilotRating = ({ rating }) => {
  const getStarColor = (rating) => {
    if (rating <= 1.7) return "#FF3D00"; // Red for bad
    if (rating <= 2.7) return "#FFA500"; // Orange for poor
    if (rating <= 3.7) return "#FFD700"; // Yellow for average
    if (rating <= 4.2) return "#ADFF2F"; // Light green for great
    return "#4CAF50"; // Dark green for excellent
  };

  const starColor = getStarColor(rating);

  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0; // Check if there's a half star

  return (
    <div className="stars" style={{ display: "flex", gap: "4px" }}>
      {[...Array(5)].map((_, index) => {
        // Fill full stars
        if (index < fullStars) {
          return <Star key={index} fillColor={starColor} />;
        }
        // Half star (if needed)
        if (index === fullStars && halfStar) {
          return (
            <Star key={index} fillColor={starColor} style={{ opacity: 0.5 }} />
          );
        }
        // Empty star
        return <Star key={index} fillColor="#E0E0E0" />; // Gray color for empty stars
      })}
    </div>
  );
};

export default TrustpilotRating;

const Star = ({ fillColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    fill={fillColor}
  >
    <path d="M12 .587l3.668 7.429 8.2 1.179-5.91 5.745 1.393 8.17L12 18.897l-7.35 3.868 1.392-8.17-5.91-5.745 8.2-1.179z" />
  </svg>
);
