import axios from "axios";
import * as types from "../types";
import { load_user } from "./accountInfo";
import { REQUEST_URL } from "../../constant/Constant";
import { getTokenIncludedConfig, config } from "../common";
import { ErrorToast, SuccessToast } from "../../components/toaster/toast";

export const socialAuthLogin = (formData) => async (dispatch) => {
  dispatch({
    type: types.AUTH_REQUEST_START,
  });

  const body = JSON.stringify(formData);
  await axios
    .post(`${REQUEST_URL}/api/accounts/auth/social/`, body, config)
    .then((res) => {
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(load_user());
    })
    .catch((error) => {
      dispatch({
        error: error.response,
        type: types.LOGIN_FAIL,
      });
    });
};

export const signup = (formData) => async (dispatch) => {
  dispatch({
    type: types.AUTH_REQUEST_START,
  });
  const body = JSON.stringify({
    ...formData,
    re_password: formData.password,
  });

  await axios
    .post(`${REQUEST_URL}/api/accounts/auth/users/`, body, config)
    .then((res) => {
      dispatch({
        type: types.SIGNUP_SUCCESS,
        payload: res.data,
      });
      SuccessToast(
        `🤩🥳 Welcome on board ${formData.first_name},  your registration was successful.`
      );
      dispatch(load_user());
    })
    .catch((error) => {
      dispatch({
        error: error.response,
        type: types.SIGNUP_FAIL,
      });
    });
};

export const login = (formData) => async (dispatch) => {
  dispatch({
    type: types.AUTH_REQUEST_START,
  });

  const body = JSON.stringify(formData);
  try {
    const res = await axios.post(
      `${REQUEST_URL}/api/accounts/auth/jwt/create/`,
      body,
      config
    );
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: res.data,
    });
    console.log(res.data)
    dispatch(load_user());
  } catch (error) {
    dispatch({
      error: error.response,
      type: types.LOGIN_FAIL,
    });
  }
};

export const checkAuthenticated = () => async (dispatch) => {
  dispatch({
    type: types.AUTH_REQUEST_START,
  });
  if (localStorage.getItem("access")) {
    const body = JSON.stringify({ token: localStorage.getItem("access") });
    try {
      const res = await axios.post(
        REQUEST_URL + `/api/accounts/auth/jwt/verify/`,
        body,
        getTokenIncludedConfig()
      );
      dispatch({
        type: types.AUTHENTICATION_SUCCESS,
      });
      dispatch(load_user());
    } catch (error) {
      dispatch({
        type: types.AUTHENTICATION_FAIL,
      });
      ErrorToast(
        "We are sorry that your session is expired. Please login again"
      );
    }
  } else {
    dispatch({
      type: types.AUTHENTICATION_FAIL,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("access");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("activeView")
  dispatch({
    type: types.LOGOUT,
  });
};

export const reset_password = (email) => async (dispatch) => {
  dispatch({
    type: types.AUTH_REQUEST_START,
  });
  const body = JSON.stringify({ email });
  try {
    const res = await axios.post(
      REQUEST_URL + `/api/accounts/auth/users/reset_password/`,
      body,
      config
    );
    dispatch({
      type: types.PASSWORD_RESET_SUCCESS,
    });
    SuccessToast(`✌ Reset password email sent successfully.`);
  } catch (error) {
    dispatch({
      error: error.response,
      type: types.PASSWORD_RESET_FAIL,
    });
  }
};

export const reset_password_confirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    dispatch({
      type: types.AUTH_REQUEST_START,
    });

    const body = JSON.stringify({ uid, token, new_password, re_new_password });
    try {
      await axios.post(
        `${REQUEST_URL}/api/accounts/auth/users/reset_password_confirm/`,
        body,
        config
      );
      dispatch({
        type: types.PASSWORD_RESET_CONFIRM_SUCCESS,
      });
      SuccessToast(
        `✌😋 Password reset successfully, we are happy to see you back.`
      );
    } catch (error) {
      dispatch({
        error: error.response,
        type: types.PASSWORD_RESET_CONFIRM_FAIL,
      });
    }
  };

  export const clearError = () => ({
    type: types.CLEAR_ERROR,
  });
