import React from "react"
import  "./FullScreenLoading.css"
export const FullScreenLoading=(props)=>{
    return <>
        {props.show?<div className="overlay" >
            <div className="overlay-content">
                {props.title}
            </div>
        </div>:''}
        </>
}