import React from "react";
import { useTranslation } from "react-i18next";
const UserAddressForm = ({ user, userProfile, setUserProfile }) => {
  const { i18n, t } = useTranslation();
  const onChange = (e) => {
    userProfile
      ? setUserProfile({ ...userProfile, [e.target.name]: e.target.value })
      : setUserProfile({ ...user.profile, [e.target.name]: e.target.value });
  };
  return (
    <div class="form-group">
      <div style={{ backgroundColor: "white" }}>
      <span style={{ color: user?.profile?.address ? "green" : "gray" }}>
          {user.email
            ? user.email
            : t("Email address")}
        </span>
        <br/>
        <span style={{ color: user?.username?.first_name ? "green" : "gray" }}>
          {user?.username &&
            user?.username?.first_name + " " + user?.username?.last_name}
        </span>
        <br />
        <span style={{ color: user?.profile?.address ? "green" : "gray" }}>
          {user?.profile?.address
            ? user.profile.address
            : t("Street and Number")}
        </span>
        <br />
        <span style={{ color: user?.profile?.phone ? "green" : "gray" }}>
          {user?.profile?.phone ? user.profile.phone : t("phone number")}
        </span>
        <br />
        <span style={{ color: user?.profile?.postcode ? "green" : "gray" }}>
          {user?.profile?.postcode ? user.profile.postcode : t("Postal Code")}
        </span>
        <br />
        <span style={{ color: user?.profile?.town ? "green" : "gray" }}>
          {user?.profile?.town ? user.profile.town : t("City")}
        </span>
        <br />
        <span style={{ color: user?.profile?.province ? "green" : "gray" }}>
          {user?.profile?.province ? user.profile.province : t("Province")}
        </span>
        <br />
        <span style={{ color: user?.profile?.country ? "green" : "gray" }}>
          {user?.profile?.country ? user.profile.country : t("Country")}
        </span>
      </div>
    </div>
  );
};

export default UserAddressForm;
