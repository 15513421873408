import React, { useEffect, useState } from "react";
import EcardVideo from "../../../../assets/eCardVideo.mp4";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";

const ECard = ({ history, id }) => {
  const { i18n, t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <h1
        style={{ fontSize: "3rem", display: "flex", justifyContent: "center" }}
      >
        {t("Gift Shop")}
      </h1>
      <div className="page-giftshop" id={id} style={{padding:"10px 0px"}}>
        <div>
          <video className="video" controls muted>
            <source src={EcardVideo} type="video/mp4" />
          </video>
        </div>
        <div>
          <h2>{t("Surprize digital Fortune Cookie")}</h2>
          <p className="mt-2">
            {t(
              "We will choose one for you from more than 700 cookies with a surprize fortune cookie message."
            )}
          </p>
          <p className="mt-2">
            {t(
              "Download it on your mobile phone and share via WhatsApp with your friends and family."
            )}
          </p>
          <HashLink
            smooth
            to="/#competitionSect"
            scroll={(el) =>
              window.scrollTo({
                top: el.offsetTop - (isMobile ? 0 : 150),
                behavior: "smooth",
              })
            }
          >
            <button className="addCartbtn">{t("Add to Cart")}</button>
          </HashLink>

          <div className="page-soldout">
            {t("Discounted via our Competitions")}
          </div>
        </div>
      </div>
    </>
  );
};

export default ECard;
