import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const CartMiddleNav = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="first-div-cont">
      <div>
        <h1>{t("Cart")}</h1>
        {/* <Link to="/competitions">
          <button className="cont-shop-btn">
            <i className="fas fa-chevron-left"></i>
            {t("CONTINUE SHOPPING")}
          </button>
        </Link>
        <Link to="/order">
          <button className="cont-shop-btn">
            <i className="fas fa-chevron-right"></i>
            {t("PROCEED TO CHECKOUT")}
          </button>
        </Link> */}
      </div>
    </div>
  );
};

export default CartMiddleNav;
