import React from "react";
import {
  Page,
  Text,
  View,
  Link,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { DateTime } from "luxon";
import { REQUEST_URL } from "../../constant/Constant";
Font.register({
  family: "Poppins",
  fonts: [
    { src: "/fonts/Poppins/Poppins-Light.ttf", fontWeight: 300 }, // font-style: normal, font-weight: normal
    { src: "/fonts/Poppins/Poppins-Regular.ttf", fontWeight: 400 },
    { src: "/fonts/Poppins/Poppins-Medium.ttf", fontWeight: 500 },
    { src: "/fonts/Poppins/Poppins-SemiBold.ttf", fontWeight: 600 },
    { src: "/fonts/Poppins/Poppins-Bold.ttf", fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    marginBottom: 5,
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 20,
    marginBottom: 5,
    fontFamily: "Poppins",
    fontWeight: 500,
    marginRight: "10px",
  },
  text: {
    fontSize: 15,
    marginBottom: 3,
    fontFamily: "Poppins",
    fontWeight: "normal",
  },
  logoText: {
    marginTop: "50",
    left: "-30px",
    fontFamily: "Poppins",
  },
  logoContainer: {
    width: 300,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logotitle: {
    fontSize: 35,
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  logosubtitle: {
    fontSize: 30,
    top: "-10px",
    fontFamily: "Poppins",
    fontWeight: 300,
  },
  image: {
    width: "150px",
    height: "150px",
  },
  btnGrp: {
    padding: "20px 0",
    alignItems: "center",
    display: "flex",
  },
  btn: {
    width: "550px",
    display: "inline-block",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#212529",
    textAlign: "center",
    textDecoration: "none",
    verticalAlign: "middle",
    padding: "10px 60px",
    backgroundColor: "#00FFEA",
    fontSize: 20,
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  flexContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  fifth: {
    width: "84.3%",
    backgroundColor: "#F0F0F0",
    flexDirection: "row",
    alignItems: "center",
  },
  itemImg: {
    width: "120px",
    height: "90px",
  },
  sixth: {
    width: "15%",
    backgroundColor: "#F0F0F0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
const PaymentConfirmation = ({ order }) => (
  <Document>
    <Page style={styles.body} size={[900]}>
      <View>
        <View style={styles.logoContainer}>
          <Image style={styles.image} src="/images/compress_logo.png" />
          <View style={styles.logoText}>
            <Text style={[styles.logotitle, { fontSize: 44, margin: "0" }]}>
              Paradise
            </Text>
            <View
              style={{ display: "flex", flexDirection: "row", top: "-5px" }}
            >
              <Text
                style={[
                  styles.logosubtitle,
                  { fontSize: 30, marginBottom: "5px", paddingBottom: "6px" },
                ]}
              >
                Competitions
              </Text>
              <Text
                style={{
                  fontSize: 27,
                  verticalAlign: "middle",
                  top: "-5px",
                  marginLeft: 2,
                }}
              >
                &#174;
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.btnGrp}>
        {/* <Text style={styles.btn}>Using Our Mobile App Already?</Text> */}
        <Text style={styles.btn}>
          Primul Digital Fortune Cookies din Lume ;-) 
        </Text>
      </View>
      <View>
        <Text style={styles.text}>
          Hello{" "}
          {order.user_first_name.charAt(0).toUpperCase() +
            order.user_first_name.slice(1)}
          ,
        </Text>
      </View>
      <View>
        <Text style={[styles.text, { marginBottom: "30px" }]}>
          Mulțumim! Plata ta a fost efectuată cu succes. Ai comandat următoarele
          produse:
        </Text>
      </View>
      {order.order_items.map((item, i) =>
        item.is_ticket ? (
          <View
            key={i}
            style={[styles.flexContainer, { marginBottom: "15px" }]}
          >
            <View style={styles.fifth}>
              <View style={{ padding: "15px 5px" }}>
                <Image style={styles.itemImg} src={item.image} />
              </View>
              <View style={{ padding: "5px 5px 5px 10px" }}>
                <Text style={[styles.text, { marginBottom: "5px" }]}>
                  {item.title.split(": ")[0]}/ Fortune Cookie digital surpriză
                  cu gratis
                </Text>
                <Text style={[styles.subtitle, { marginBottom: "15px" }]}>
                  {item.title.split(": ")[1]} Bilet de Intrare:
                </Text>
                <Text style={[styles.text, { marginBottom: "5px" }]}>
                  Descoperă-ți Fortune Cookie
                  <Link style={{ textDecoration: "none" }} src={item.ecard_url}>
                    {" "}
                    aici
                  </Link>{" "}
                  și verifică starea competiției{" "}
                  <Link
                    style={{ textDecoration: "none" }}
                    src={`${REQUEST_URL}/competitions/sub-section/${
                      item.title.split(": ")[0]
                    }#timer`}
                  >
                    aici
                  </Link>
                </Text>
              </View>
            </View>
            <View style={styles.sixth}>
              <Text style={styles.subtitle}>{item.ticket_title}</Text>
            </View>
          </View>
        ) : (
          <></>
        )
      )}

      {order.order_items.some((item) => item.is_ticket === true) ? (
        <>
          <View>
            <Text style={[styles.text, { marginTop: "20" }]}>
              Mult noroc la tragere!
            </Text>
          </View>
          <View>
            <Text style={[styles.text, { marginBottom: "30px" }]}>
              După tragere, câștigătorii vor fi contactați automat de către noi.
            </Text>
          </View>
        </>
      ) : (
        <></>
      )}
      {order.order_items.map(
        (item, i) =>
          !item.is_ticket && (
            <View
              key={i}
              style={[styles.flexContainer, { marginBottom: "15px" }]}
            >
              <View style={styles.fifth}>
                <View style={{ padding: "15px 5px" }}>
                  <Image style={styles.itemImg} src={item.image} />
                </View>
                <View style={{ padding: "5px 5px 5px 10px" }}>
                  <Text style={[styles.subtitle, { marginBottom: "15px" }]}>
                    {item.title}
                  </Text>
                  {/* <Text style={[styles.subtitle, { marginBottom: "15px" }]}>
                    Cardigan, Size M
                  </Text> */}
                  <Text style={[styles.text, { marginBottom: "5px" }]}>
                    {item.quantity} item
                  </Text>
                </View>
              </View>
              <View style={styles.sixth}>
                <Text style={styles.subtitle}>{item.price}&nbsp;Lei</Text>
              </View>
            </View>
          )
      )}
      <View style={[styles.flexContainer, { marginBottom: "15px" }]}>
        <View style={[styles.sixth, { backgroundColor: "white" }]}></View>
        <View style={[styles.fifth, { backgroundColor: "white" }]}>
          <View style={{ padding: "5px 5px 5px 10px" }}>
            <Text style={[styles.text, { marginBottom: "15px" }]}>
              Produsul va fi expediat în termen de 5 zile lucrătoare către:
            </Text>
            <Text
              style={[styles.subtitle, { marginBottom: "0px", fontSize: 17 }]}
            >
              {order.user_first_name} {order.user_last_name}
            </Text>
            <Text
              style={[styles.subtitle, { marginBottom: "0px", fontSize: 17 }]}
            >
              {order.address}
            </Text>
            <Text
              style={[styles.subtitle, { marginBottom: "0px", fontSize: 17 }]}
            >
              {order.postcode} {order.town}
            </Text>
            <Text
              style={[styles.subtitle, { marginBottom: "0px", fontSize: 17 }]}
            >
              {order.province}
            </Text>
            <Text
              style={[styles.subtitle, { marginBottom: "15px", fontSize: 17 }]}
            >
              {order.country}
            </Text>
            <Text style={[styles.text, { marginBottom: "5px" }]}>
              Numărul de confirmare al comenzii tale este #{order.id}
            </Text>
          </View>
        </View>
      </View>
      <View style={{ marginTop: 20 }}>
        <Text style={[styles.text, { marginBottom: "15px" }]}>
          Îți mulțumim pentru încrederea acordată serviciilor noastre.
        </Text>
        <Text style={[styles.text, { marginBottom: "15px" }]}>
          Echipa Paradise Competitions
        </Text>
      </View>
      <View style={{ fontSize: 12, marginTop: "40" }}>
        {/* <Text style={[styles.text, { marginBottom: "15px" }]}>
          Please note NO-Reply: this email was sent from a notification-only
          address that cannot accept incomming e-mail. Please do not reply to
          this message
        </Text> */}
        <Text style={[styles.text, { marginBottom: "15px", fontSize: 12 }]}>
          Pentru articolul(ele) dvs. achiziționat(e) de pe
          www.paradisecompetitions.ro vânzătorul poate solicita informații
          suplimentare precum numărul de TVA sau codul dvs. de TVA, pentru a
          emite un cod valid.
        </Text>
        <Text style={[styles.text, { marginBottom: "15px", fontSize: 12 }]}>
          Acest e-mail este doar o confirmare de primire a comenzii dvs.
          Contractul dvs. de achiziție a acestor articole nu este complet până
          când nu vă trimitem un e-mail prin care vă anunțăm că articolele au
          fost expediate.
        </Text>
        <Text
          class="text-center my-5"
          style={{ fontSize: 12, textAlign: "center", marginTop: "40" }}
        >
          {`PDF generat pe ${moment(order.ordered_at.trim())
            .locale("ro")
            .format("LL")} la ${DateTime.fromFormat(
            order.ordered_at.trim(),
            "dd LLL, yyyy HH:mm:ss"
          ).toFormat("HH:mm:ss")}`}
        </Text>
      </View>
    </Page>
  </Document>
);

export default PaymentConfirmation;
