import axios from "axios";
import { config } from "./common";
import { SuccessToast } from "../components/toaster/toast";
import * as types from "./types";
import { REQUEST_URL } from "../constant/Constant";

export const subscribeForNewsLetter = (email) => async (dispatch) => {
  dispatch({
    type: types.COMMON_REQUEST_START,
  });
  try {
    const body = JSON.stringify({ email });
    await axios.post(
      REQUEST_URL + `/api/accounts/newsletter/subscribe/`,
      body,
      config
    );
    dispatch({
      type: types.NEWSLETTER_SUBSCRIPTION_SUCCESS,
    });
    SuccessToast(`🤩 Thanku for subscribing our news letter.`);
  } catch (error) {
    dispatch({
      error: error.response,
      type: types.NEWSLETTER_SUBSCRIPTION_FAIL,
    });
  }
};

export const getCompetitions = () => async (dispatch) => {
  dispatch({
    type: types.COMPETITION_REQUEST_START,
  });
  try {
    const res = await axios.get(`${REQUEST_URL}/api/competitions/`);
    dispatch({
      type: types.GET_COMPETITIONS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: types.GET_COMPETITIONS_FAIL,
    });
  }
};

export const getHeroPrizeCompetitions = () => async (dispatch) => {
  dispatch({
    type: types.COMPETITION_REQUEST_START,
  });
  try {
    const res = await axios.get(`${REQUEST_URL}/api/competitions/heroprize/`);
    dispatch({
      type: types.GET_HEROPRIZE_COMPETITIONS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: types.GET_HEROPRIZE_COMPETITIONS_FAIL,
    });
  }
};

export const getTotalGiveAway = () => async (dispatch) => {
  dispatch({
    type: types.TOTAL_GIVE_AWAY_REQUEST_START,
  });
  try {
    const res = await axios.get(
      `${REQUEST_URL}/api/competitions/total/giveaway/`
    );
    dispatch({
      type: types.GET_TOTAL_GIVE_AWAY_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: types.GET_TOTAL_GIVE_AWAY_FAIL,
    });
  }
};
