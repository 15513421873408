export function getTokenIncludedConfig() {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };
}

export const config = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
