import axios from "axios";
import * as types from "../types";
import { getTokenIncludedConfig } from "../common";
import { REQUEST_URL } from "../../constant/Constant";
import { ErrorToast, SuccessToast } from "../../components/toaster/toast";

export const getUserTickets = (flag,page) => async (dispatch) => {
  dispatch({
    type: types.AUTH_REQUEST_START,
  });
  try {
    const res = await axios.get(
      `${REQUEST_URL}/api/profile/tickets/?flag=${flag}&page=${page}`,
      getTokenIncludedConfig()
    );
    dispatch({
      type: types.GET_USER_TICKETS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      error: error.response,
      type: types.GET_USER_TICKETS_FAIL,
    });
  }
};

export const getUserTransactions = (page) => async (dispatch) => {
  dispatch({
    type: types.AUTH_REQUEST_START,
  });
  try {
    const res = await axios.get(
      `${REQUEST_URL}/api/profile/transactions/?page=${page}`,
      getTokenIncludedConfig()
    );
    dispatch({
      type: types.GET_USER_TRANSACTIONS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      error: error.response,
      type: types.GET_USER_TRANSACTIONS_FAIL,
    });
  }
};

export const getPayoutTransactions = (page) => async (dispatch) => {
  dispatch({
    type: types.AUTH_REQUEST_START,
  });
  axios
    .get(REQUEST_URL + `/api/payouts?page=${page}`, getTokenIncludedConfig())
    .then((response) => {
      dispatch({
        type: types.GET_USER_PAYOUT_TRANSACTION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        error: error.response,
        type: types.GET_USER_PAYOUT_TRANSACTION_FAIL,
      });
    });
};

export const getUserAffiliateInfo = () => async (dispatch) => {
  return axios
    .get(
      REQUEST_URL + `/api/profile/affiliate/balance`,
      getTokenIncludedConfig()
    )
    .then((response) => {
      dispatch({
        type: types.GET_USER_AFFILIATE_INFO_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        error: error.response,
        type: types.GET_USER_AFFILIATE_INFO_FAIL,
      });
    });
};

export const withDrawBalance = (setLoading) => async (dispatch) => {
  axios
    .get(
      REQUEST_URL + "/api/profile/stripe/withdraw/",
      getTokenIncludedConfig()
    )
    .then((response) => {
      setLoading(false);
      SuccessToast(
        "Withdrawl success in stripe, redirecting you to your stripe account to manage or add bank details to recieve funds."
      );

      dispatch({
        type: types.WITHDRAW_BALANCE_SUCCESS,
      });
    })
    .catch((error) => {
      setLoading(false);
      dispatch({
        type: types.WITHDRAW_BALANCE_FAIL,
      });
      ErrorToast(error.response.data.error);
    })
    .finally((err) => {
      setLoading(false);
      getUserAffiliateInfo();
    });
};

export const connectWithPaypal = (event) => async (dispatch) => {
  axios
    .post(
      REQUEST_URL + "/api/profile/paypal/connect",
      event.body,
      getTokenIncludedConfig()
    )
    .then((response) => {
      dispatch({
        type: types.CONNECT_WITH_PAYPAL_SUCCESS,
      });
      SuccessToast("wohoo, You are now connected with paypal!");
    })
    .catch((error) => {
      dispatch({
        type: types.CONNECT_WITH_PAYPAL_FAIL,
      });
      ErrorToast(error.response.data.error);
    });
};
