import React from "react";
import Section1 from "./Section-1/section-1.jsx";

import Navbar from "../Landing-page/Navbar/navbar.jsx";

import Footer from "../Landing-page/Footer/footer.jsx";

const Competitions = () => {
  return (
    <section className="main-section">
      <Navbar />
      <Section1 />
      <Footer />
    </section>
  );
};

export default Competitions;
