import React from "react";
import "./PaymentScreen.css";
import { useTranslation } from "react-i18next";
import { PaymentButton } from "../../../../../business/PaymentButton";

const PaymentScreens = ({
  userProfile,
  subtotal,
  total,
  user,
  userAffiliateInfo,
}) => {
  console.log("userAffiliateInfo ", userAffiliateInfo);
  const { i18n, t } = useTranslation();
  return (
    <div style={{ marginTop: "100px !important" }}>
      <hr />
      {subtotal > 2 && (
        <div>
          <h2 className="m-0 py-3 text-center title-payment ">
            {t("Select Payment Method now")}:
          </h2>

          <PaymentButton
            userProfile={userProfile ? userProfile : user.profile}
            total={total}
            title={t("Pay with Card")}
            is_site_cash={false}
          />
          {userAffiliateInfo.site_cash > total ? (
            <PaymentButton
              userProfile={userProfile ? userProfile : user.profile}
              total={total}
              title={t("Pay with Site Credit ")}
              is_site_cash={true}
              userAffiliateInfo={userAffiliateInfo}
              // is_site_cash_order={}
            />
          ) : (
            ""
          )}

          <p
            style={{
              color: "red",
              textAlign: "center",
              marginTop: "10px",
              fontSize: "12px",
            }}
          >
            {t(
              "To help ensure that your shopping experience is safe, simple and secure,"
            )}
            <br />
            <strong>
              {t(
                "Paradise Competitions uses SSL technology (Secure Sockets Layer)"
              )}
            </strong>
          </p>
        </div>
      )}
    </div>
  );
};

export default PaymentScreens;
