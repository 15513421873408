import "./AffiliateIncome.css";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PaypalButton } from "./childs/paypalbutton.jsx";
import { AffiliateInfo } from "./childs/affiliateinfo.jsx";
import { PayoutTransactions } from "./childs/payouttransactions.jsx";
import { getUserAffiliateInfo } from "../../../../../actions/user/profileActions";

export const AffiliateIncome = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const userAffiliateInfo = state.user.userAffiliateInfo;

  useEffect(() => {
    dispatch(getUserAffiliateInfo());
  }, []);

  return (
    <section className="wallet">
      <AffiliateInfo userAffiliateInfo={userAffiliateInfo} />
      {/* <PaypalButton userAffiliateInfo={userAffiliateInfo} /> */}
      <PayoutTransactions />
    </section>
  );
};
