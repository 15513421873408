import React from "react";
import "./ecard.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EcardVideo from "../../../../../../assets/eCardVideo.mp4";

export const Ecard = ({ competition }) => {
  const [seeAll, setSeeAll] = useState(false);
  const { i18n, t } = useTranslation();
  return (
    <section className="section-2">
      <span className="stickyBar">
      <div style={{display:"flex" ,backgroundColor:"#161413"}} onClick={() => setSeeAll(!seeAll)}>
          {/* <p className="cardSec"> */}
          <h1 className="fortune">
            {seeAll? <span>-</span>:<span>+</span>} 
            Fortune Cookie</h1>
        </div>
        <hr/></span>
        <div className={`paragraph ${seeAll ? "show" : "hide"}`}>
      <div className="eCard">
        <div style={{ position: "relative" }}>
          <video controls muted>
            <source src={EcardVideo} type="video/mp4" />
          </video>
          {/* <h2 className='video_uppar_text'>Video 000</h2> */}
          <h1>
            <span>
              <h1>
                <span
                  className={competition.discount_active ? "discount-bar" : ""}
                >
                  Lei {competition.first_day_price}
                </span>
                {competition &&
                  competition.discount_active &&
                  competition.discount_price && (
                    <span style={{ fontWeight: "600" }}>
                      {" "}
                      Lei{" "}
                      {competition &&
                        competition.discount_active &&
                        competition.discount_price}
                    </span>
                  )}
              </h1>
            </span>
          </h1>
        </div>
        <div style={{ color: "rgb(210,210,210)" }}>
          <p style={{ color: "rgb(210,210,210)" }}>
            {t("Surprize digital Fortune Cookie")}
          </p>
          <p style={{ color: "rgb(210,210,210)" }}>
            {t("Discounted compared to Gift Shop")}
          </p>
          <p style={{ color: "rgb(210,210,210)" }}>
            {t("Buy one here, receive one entry to the competition for free")}{" "}
            {t("See below for free entry method")}
          </p>
        </div>
      </div></div>
    </section>
  );
};
