import jwt_decode from "jwt-decode";

export function getAlphabetsList(range) {
  const [start, end] = range.toUpperCase().split("-").map(char => char.charCodeAt(0));
  console.log(Array.from({ length: end - start + 1 }, (_, i) => String.fromCharCode(start + i)))
  return Array.from({ length: end - start + 1 }, (_, i) => String.fromCharCode(start + i));
}

export const checkToActiveTicket = (customer) => {
  const decoded = localStorage.getItem("access") && jwt_decode(localStorage.getItem("access"));
  return (decoded && decoded.user_id === customer) ? "gold" : "#E0115F";
};
