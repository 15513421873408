import React, { useState } from "react";
import "./CCVPayment.css";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ErrorToast } from "../../components/toaster/toast";
import { makePayment } from "../../actions/paymentactions/worldlineactions";
import { FullScreenLoading } from "../../components/loading/FullScreenLoading";

export const PaymentButton = ({
  userProfile,
  total,
  title,
  is_site_cash,
  userAffiliateInfo,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [paymentLoading, setPaymentLoading] = useState(false);

  const updateOrderUrl = (order_id) => {
    const queryParams = new URLSearchParams();
    queryParams.set("success", "true");
    queryParams.set("session_id", order_id);

    history.push({
      pathname: "/order",
      search: "?" + queryParams.toString(),
    });
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Button
          className="credit-card-btn"
          variant="secondary"
          size="lg"
          onClick={() =>
            total >= 2
              ? dispatch(
                  makePayment(setPaymentLoading, userProfile, is_site_cash, updateOrderUrl)
                )
              : ErrorToast(
                  "Total amount due must add up to at least Lei2.00 ron"
                )
          }
        >
          {title}
          {is_site_cash && (
            <h5 style={{ color: "green" }}>
              {t("available balance")}: {userAffiliateInfo.site_cash} Lei
            </h5>
          )}
        </Button>
        <FullScreenLoading title="Please wait..." show={paymentLoading} />
      </div>
    </>
  );
};
