import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { DateTime } from "luxon";

Font.register({
  family: "Poppins",
  fonts: [
    { src: "/fonts/Poppins/Poppins-Light.ttf", fontWeight: 300 }, // font-style: normal, font-weight: normal
    { src: "/fonts/Poppins/Poppins-Regular.ttf", fontWeight: 400 },
    { src: "/fonts/Poppins/Poppins-Medium.ttf", fontWeight: 500 },
    { src: "/fonts/Poppins/Poppins-SemiBold.ttf", fontWeight: 600 },
    { src: "/fonts/Poppins/Poppins-Bold.ttf", fontWeight: 700 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    marginBottom: 5,
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 20,
    marginBottom: 5,
    fontFamily: "Poppins",
    fontWeight: 500,
    marginRight: "10px"
  },
  text: {
    fontSize: 18,
    marginBottom: 3,
    fontFamily: "Poppins",
    fontWeight: "normal",
  },
  paid: {
    position: "absolute",
    right: "-100px",
    height: "70px",
    top: "0px",
    width: "340px",
    transform: "rotate(40deg)",
    backgroundColor: "greenyellow",
    color: "white",
    fontFamily: "Poppins",
  },
  logoContainer: {
    width: 300,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  logoText: {
    marginTop: "50",
    left: "-30px",
    fontFamily: "Poppins",
  },
  logotitle: {
    fontSize: 35,
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  logosubtitle: {
    fontSize: 30,
    top: "-10px",
    fontFamily: "Poppins",
    fontWeight: 300,
  },
  image: {
    width: "150px",
    height: "150px",
  },
  graySection: {
    marginTop: "10px",
    padding: "5px 3px",
    backgroundColor: "#F0F0F0",
  },
  flexContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    minHeight: "20px",
    paddingBottom: 5,
  },
  first: {
    width: "20%",
  },
  second: {
    width: "40%",
  },
  third: {
    width: "20%",
    textAlign: "right",
  },
  fourth: {
    width: "20%",
    textAlign: "right",
    flexWrap: "wrap",
  },
  fifth: {
    width: "80%",
    textAlign: "right",
  },
  sixth: {
    width: "20%",
    textAlign: "right",
  },
});

const PaymentInvoice = ({ order }) => (
  <Document>
    <Page style={styles.body} size={[900]}>
      <View>
        <View style={styles.paid}>
          <Text
            style={{
              fontSize: 40,
              color: "white",
              transform: "translate(140px, 5px)",
            }}
          >
            PLĂTIT
          </Text>
        </View>
        <View style={styles.logoContainer}>
          <Image style={styles.image} src="/images/compress_logo.png" />
          <View style={styles.logoText}>
            <Text style={[styles.logotitle, { fontSize: 44, margin: "0" }]}>
              Paradise
            </Text>
            <View
              style={{ display: "flex", flexDirection: "row", top: "-5px" }}
            >
              <Text
                style={[
                  styles.logosubtitle,
                  { fontSize: 30, marginBottom: "5px", paddingBottom: "6px" },
                ]}
              >
                Competitions
              </Text>
              <Text
                style={{
                  fontSize: 27,
                  verticalAlign: "middle",
                  top: "-5px",
                  marginLeft: 2,
                }}
              >
                &#174;
              </Text>
            </View>
          </View>
        </View>
      </View>
      {/* <View style={{ textAlign: "right" }}>
        <Text style={styles.text}>Company Ltd</Text>
        <Text style={styles.text}>PO Box 123456</Text>
        <Text style={styles.text}>ZIP City</Text>
        <Text style={styles.text}>COuntry</Text>
        <Text style={styles.text}>Reg. 123.456.789</Text>
      </View> */}
      <View style={styles.graySection}>
        <Text style={styles.title}>Factura# {order.id}</Text>
        <Text style={styles.text}>
          Data facturii{" "}
          {moment(order.ordered_at.trim()).locale("ro").format("LL")}
           
          {/* {DateTime.fromFormat(
            order.ordered_at.trim(),
            "dd LLL, yyyy HH:mm:ss",
            
          ).toFormat("dd LLL, yyyy")} */}
        </Text>
      </View>
      <View style={{ paddingVertical: 10 }}>
        <Text style={styles.subtitle}>factura către</Text>
        <Text style={styles.text}>
          {order.user_first_name} {order.user_last_name}
        </Text>
        <Text style={styles.text}>{order.user_email}</Text>

        <Text style={styles.text}>{order.address}</Text>
        <Text style={styles.text}>
          {order.postcode} {order.town}
        </Text>
        <Text style={styles.text}>{order.province}</Text>
        <Text style={styles.text}>{order.country}</Text>
      </View>
      <View style={styles.graySection}>
        <Text style={styles.title}>Descriere</Text>
      </View>
      <View style={{ paddingVertical: 10 }}>
        <View>
          {order.order_items.map((item, i) => (
            <View key={i} style={styles.flexContainer}>
              <Text style={[styles.text, styles.first]}>{item.id}</Text>
              <Text style={[styles.text, styles.second]}>
                {item.is_ticket
                  ? `Fortune Cookie/${item.ticket_title}`
                  : item.title}
              </Text>
              <Text style={[styles.text, styles.third]}>
                {item.quantity}&nbsp;BUC
              </Text>
              <Text style={[styles.text, styles.fourth]}>
                {item.price}&nbsp;Lei
              </Text>
            </View>
          ))}
        </View>
        <View style={[styles.graySection, styles.flexContainer]}>
          <Text style={[styles.subtitle, styles.fifth]}>Total</Text>
          <Text style={[styles.subtitle, styles.sixth]}>
            {order.total}&nbsp;Lei
          </Text>
        </View>
        <View>
          <View style={[styles.flexContainer, { marginTop: 10 }]}>
            <Text style={[styles.text, styles.fifth]}>total fără TVA</Text>
            <Text style={[styles.text, styles.sixth]}>
              {(order.total / 1.19).toFixed(2)}&nbsp;Lei
            </Text>
          </View>
          <View style={styles.flexContainer}>
            <Text style={[styles.text, styles.fifth]}>TVA 19%</Text>
            <Text style={[styles.text, styles.sixth]}>
              {((order.total / 1.19).toFixed(2) * 0.19).toFixed(2)}&nbsp;Lei
            </Text>
          </View>
        </View>
        <View style={styles.graySection}>
          {/* <Text style={styles.title}>Transaction</Text> */}
          <View style={styles.flexContainer}>
            <Text style={[styles.text, styles.first]}>Data tranzacției</Text>
            <Text style={[styles.text, styles.second]}>
              Metodă/gateway de plată
            </Text>
            <Text style={[styles.text, styles.third]}>ID tranzacției </Text>
            <Text style={[styles.text, styles.fourth]}>Total</Text>
          </View>
        </View>
        <View style={{ marginTop: 10 }}>
          <View style={styles.flexContainer}>
            <Text style={[styles.text, styles.first]}>
            {moment(order.ordered_at.trim()).locale("ro").format("LL")}
            </Text>
            <Text style={[styles.text, styles.second]}>
              {order.order_transaction.payment_type}
            </Text>
            <Text style={[styles.text, styles.third]}>
              {order.order_transaction.id}
            </Text>
            <Text style={[styles.text, styles.fourth]}>
              {order.total}&nbsp;Lei
            </Text>
          </View>
        </View>
        <View style={[styles.graySection, styles.flexContainer]}>
          <Text style={[styles.subtitle, styles.fifth]}>sold</Text>
          <Text style={[styles.subtitle, styles.sixth]}>0.00&nbsp;Lei</Text>
        </View>
        <View>
          <Text
            class="text-center my-5"
            style={{ fontSize: 12, textAlign: "center", marginTop: "20" }}
          >
            Glam Infostyle SRL / Strada Pajurei 13 / 013152 Sector 1 Bucuresti /
            Romania / RO36488183
          </Text>
          <Text
            class="text-center my-5"
            style={{ fontSize: 12, textAlign: "center", marginTop: "20" }}
          >
            {`PDF generat pe ${moment(order.ordered_at.trim()).locale("ro").format("LL")} la ${DateTime.fromFormat(
              order.ordered_at.trim(),
              "dd LLL, yyyy HH:mm:ss",
            ).toFormat("HH:mm:ss")}`}
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default PaymentInvoice;
