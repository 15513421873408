import React from "react";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const CalenderSection = ({
  // loading,
  // getPreviousDate,
  // getNextDate,
  // selectedDate,
}) => {
  const { i18n, t } = useTranslation();
  return (
    <div>
      <h1>{t("Winners Podium")}</h1>
      {/* {loading && (
        <div
          className="items-container"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
        </div>
      )} */}
      {/* {!loading && (
        <div>
          <div className="date">
            <i
              className="fas fa-chevron-left prevBtn"
              onClick={getPreviousDate}
            ></i>

            <h1 className="month">{selectedDate}</h1>
            <i
              className="fas fa-chevron-right nextBtn"
              onClick={getNextDate}
            ></i>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default CalenderSection;
