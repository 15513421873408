import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

import "./privacy-policy.css";
import Footer from "../Footer/footer";
import Navbar from "../Navbar/navbar.jsx";

const PrivacyPolicy = ({ i18n }) => {
  const state = useSelector((state) => state);
  const { content } = state.common;

  return (
    <>
      <Navbar />
      <section
        class="privacy"
        id="privacy-title"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h4 class="mt-lg-4">Privacy Policy & Cookies Policy</h4>
        {i18n.language == "ro"
          ? ReactHtmlParser(
              content?.filter((item) => item.type.name === "PrivacyPolicy")[0]
                ?.ro_richtext
            )
          : ReactHtmlParser(
              content?.filter((item) => item.type.name === "PrivacyPolicy")[0]
                ?.en_richtext
            )}
      </section>

      <Footer />
    </>
  );
};

export default withTranslation()(PrivacyPolicy);
