import React, { useEffect } from "react";
import Swiper from "swiper/bundle";
import moment from "moment";

const CompetitionSection = ({ loading, archivedCompetitions }) => {
  useEffect(() => {
    new Swiper(".winner .swiper", {
      slidesPerView: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }, [loading]);
  return (
    <div className="portfolio-item-container">
      {!loading &&
        archivedCompetitions &&
        archivedCompetitions.map((obj, i) => {
          return (
            <div className="portfolio-item" key={i}>
              <div className="swiper swiper">
                <div className="winner-content_hov">
                  <h2 style={{ fontSize: "30px", textTransform: "capitalize" }}>
                    {obj.competition_title}
                  </h2>
                  <p>won by</p>
                  <h1>{obj.winner_name}</h1>
                  <p>Winning ticket {obj.winner_ticket}</p>
                  {!obj.is_insta_winner && (
                    <p>Live draw from {moment(obj.date).format("LL")}</p>
                  )}
                  {obj.is_insta_winner && (
                    <p>Won on {moment(obj.date).format("LL")}</p>
                  )}
                </div>
                <div className="swiper-wrapper">
                  {obj.images.map((image, k) => {
                    return (
                      <div
                        className="swiper-slide"
                        key={k}
                        style={{
                          backgroundImage: `url(${image.image})`,
                        }}
                      ></div>
                    );
                  })}
                </div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
              </div>
              <a href={obj.live_draw_link} target="_blank">
                <button style={{ cursor: "pointer" }}>See Live Draw</button>
              </a>
            </div>
          );
        })}
    </div>
  );
};

export default CompetitionSection;
