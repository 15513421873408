import React, { useState } from "react";
import "./newsletter.css";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../Landing-page/Navbar/navbar.jsx";
import { subscribeForNewsLetter } from "../../../../actions/landingPageActions";
// import CheckoutForm from "./stripe.jsx";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  useStripe,
  useElements,
  CardElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm.jsx";

const stripePromise = loadStripe(
  "pk_test_51NzKuLJLZd5sqWPJWXoZvkF7kTaoPJq6M88CDXrTQaHRSWQpigHW0egOZJDFx3yD5j2itKpXmECyoYoLNZmGOsQB009TWf9egF"
);

function Newsletter() {
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "pi_3OSF2uJLZd5sqWPJ1YqhAzKs_secret_LPjfSnhSw0CazCRxfJtylGEuk",
  };
  // const stripe = useStripe();
  // const elements = useElements();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { newsLetterSuccess, loading } = state.common;
  const [formData, setFormData] = useState({
    email: "",
  });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    const { email } = formData;
    dispatch(subscribeForNewsLetter(email));
  };
  if (newsLetterSuccess) {
    return <Redirect to="/" />;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Navbar />
      <div className="logIn">
        <div className="form-modal">
          <div className="form-toggle">
            <button id="login-toggle" disabled>
              News Letter Request
            </button>
          </div>

          <div id="login-form">
            <form onSubmit={(e) => onSubmit(e)}>
              <input
                required
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={(e) => onChange(e)}
              />
              <button type="submit" className="btn login">
                {loading ? (
                  <Loader
                    style={{ display: "inline-block" }}
                    type="ThreeDots"
                    color="white"
                    height={5}
                    width={30}
                  />
                ) : (
                  ""
                )}
                Subscribe
              </button>
            </form>
          </div>
          {/* <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements> */}
        </div>
      </div>
    </>
  );
}

export default Newsletter;
