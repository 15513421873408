import React, { useEffect, useState } from "react";
import Section1 from "./Section-1/section-1.jsx";
import CompetitionSection from "./CompetitionSection/competitionSection.jsx";
import WinnerSection from "./WinnerSection/winnerSection.jsx";
import Section4 from "./Section4/section-4.jsx";

import HGiftShop from "./Gift-Shop/gift-shop.jsx";

function MainSection() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="main-section">
      <Section1 />
      <CompetitionSection />
      <WinnerSection />
      {!isMobile && <Section4 />}
      <HGiftShop />
    </section>
  );
}

export default MainSection;
