import React from "react";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import "./faq.css";
import Footer from "../Footer/footer";
import Navbar from "../Navbar/navbar.jsx";

const FAQ = ({ i18n }) => {
  const state = useSelector((state) => state);
  const { content } = state.common;

  function getAnswer(question) {
    const questionNumber = question.type.name.replace("Q", "");
    const answerName = `Answer${questionNumber}`;
    const answer = content
      ?.filter((item) => item.type.type === 2)
      .find((item) => item.type.name === answerName && item.is_active);

    if (answer) {
      return i18n.language === "en" ? answer.en_content : answer.ro_content;
    }
    return "No answer provided by the admin";
  }

  return (
    <>
      <Navbar />
      <section className="FAQ" id="faq-title">
        <h1>FAQ</h1>
        <div className="container my-lg-5 p-0 m-0">
          <div
            className="accordion md-accordion"
            id="accordionEx"
            role="tablist"
            aria-multiselectable="true"
          >
            {content
              ?.filter((item) => item.type.type === 2)
              ?.filter(
                (item) =>
                  item.type.name.startsWith("Q") && item.is_active == true
              )
              .map((ques, index) => (
                <div className="card" key={index}>
                  <div
                    className="card-header"
                    role="tab"
                    id={`heading${index}`}
                  >
                    <a
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}
                    >
                      <h5 className="mb-0">
                        {i18n.language == "en"
                          ? ques.en_content
                          : ques.ro_content}
                      </h5>
                      <i className="fas fa-angle-down rotate-icon"></i>
                    </a>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby={`heading${index}`}
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      <p>{getAnswer(ques)}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default withTranslation()(FAQ);
