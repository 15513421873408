import axios from "axios";
import * as types from "../types";
import { getTokenIncludedConfig } from "../common";
import { REQUEST_URL, WS_REQUEST_URL } from "../../constant/Constant";
import { loadTickets } from "../competitions/singleCompetitions";
import { ErrorToast, SuccessToast } from "../../components/toaster/toast";

export const addToCart =
  (is_ticket = true, obj) =>
  async (dispatch) => {
    dispatch({
      type: types.CART_REQUEST_START,
    });
    const body = JSON.stringify(obj);
    try {
      await axios
        .post(REQUEST_URL + `/api/carts/add/`, body, getTokenIncludedConfig())
        .then((res) => {
          SuccessToast(
            `🥳 ${res.data.ticket_name} successfully added to your cart. ✌`
          );
          dispatch({
            type: types.ADD_TO_CART_SUCCESS,
            payload: res.data,
          });
        });
    } catch (error) {
      if (is_ticket) {
        dispatch(loadTickets(obj.comp_id, obj.key));
        SuccessToast(
          error.response.data.message?.[0]
        );
      }
      dispatch({
        type: types.ADD_TO_CART_FAIL,
      });
    }
  };

export const getCartData = () => async (dispatch) => {
  dispatch({
    type: types.CART_REQUEST_START,
  });
  await axios
    .get(REQUEST_URL + `/api/carts/active/data/`, getTokenIncludedConfig())
    .then((res) => {
      dispatch({
        type: types.GET_CART_DATA_SUCCESS,
        payload: res.data.items,
      });
      dispatch(getDeletedItem(res.data.id))
    })
    .catch(function (error) {
      dispatch({
        type: types.GET_CART_DATA_FAIL,
      });
    });
};


export const getDeletedItem = (cart_id) => async (dispatch) => {
  const socket = new WebSocket(`${WS_REQUEST_URL}/ws/cart/cart_${cart_id}/`);
  socket.onopen = () => {
    console.log("Websocket Connected")
  };
  socket.onmessage = (event) => {
    console.log("Websocket onMessage: ", event)
    dispatch({
      type: types.UPDATE_CART_SUCCESS,
      payload: JSON.parse(event.data),
    });
  };

  socket.onclose = () => {
    console.log("Websocket Closed")
    // dispatch(getCartData())
  };
};


export const deleteCartItem = (id) => async (dispatch) => {
  dispatch({
    type: types.CART_REQUEST_START,
  });
  await axios
    .delete(`${REQUEST_URL}/api/carts/delete/${id}/`, getTokenIncludedConfig())
    .then((res) => {
      dispatch({
        type: types.DELETE_CART_ITEM_SUCCESS,
        payload: { id: id },
      });
    })
    .catch(function (error) {
      dispatch({
        type: types.DELETE_CART_ITEM_FAIL,
      });
    });
};

export const getDiscounts = () => async (dispatch) => {
  await axios
    .get(REQUEST_URL + `/api/carts/discounts/`, getTokenIncludedConfig())
    .then((res) => {
      dispatch({
        type: types.GET_DISCOUNTS,
        payload: res.data,
      });
    });
};
