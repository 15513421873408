import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBanner } from "../../../../actions/competitions/singleCompetitions";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser"
import "./PromoBar.css";
function PromoBar() {
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const { banner } = state.competitions;
  const dispatch = useDispatch();
   
  useEffect(() => {
    dispatch(getBanner());
  }, [dispatch]);

  return (
    <>
      {banner?.length > 0 ? (
        banner.map((item, index) => (
          item?.is_active && (
            <div
              key={index}
               className="bar"     >
                 {i18n.language == "ro"
                    ? ReactHtmlParser(item.ro_content)
                    : ReactHtmlParser(item.en_content)}
            </div>
          )
        ))
      ) : (
        <></>
      )}
    </>
  );
}

export default PromoBar;
