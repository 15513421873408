import React, { useEffect } from "react";
import "./gift-shop.css";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import EcardVideo from "../../../../../assets/eCardVideo.mp4";
import { loadGiftShop } from "../../../../../actions/giftshop/giftshop";

const HGiftShop = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const items = state.giftshop.items;
  useEffect(() => {
    dispatch(loadGiftShop());
  }, []);
  return (
    <div className="h-giftshop">
      <h1 style={{ fontWeight: 200 }}>{t("Gift Shop")}</h1>
      <div>
        <div>
          <HashLink className="hash-link" to="/giftshop#navbar_id">
            <video controls muted>
              <source src={EcardVideo} type="video/mp4" />
            </video>
          </HashLink>
        </div>
        {items &&
          items
            .filter((item) => item.displayed_in_shop === true)
            .slice(0, 2)
            .map((obj, i) => (
              <div key={obj.id}>
                <HashLink className="hash-link" to="/giftshop#navbar_id">
                  <figure
                    style={{ backgroundImage: `url(${obj.image})` }}
                  ></figure>
                </HashLink>
              </div>
            ))}
      </div>
    </div>
  );
};

export default HGiftShop;
