import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OrderItems from "./childs/OrderItems/OrderItems";
import TotalAmounts from "./childs/TotalAmounts/TotalAmounts";
import PaymentScreens from "./childs/PaymentScreens/PaymentScreens";
import UserAddressForm from "./childs/UserAddressForm/UserAddressForm";

const OrderPageBodyComponent = ({ user, cartLoading, discounts, cart, userAffiliateInfo }) => {
  const { i18n, t } = useTranslation();
  const [userProfile, setUserProfile] = useState(null);
  const [showPaymentButtons, setShowPaymentButtons] = useState(false);
  let discount =
    discounts &&
    discounts.reduce((acc, item) => acc + item.amount, 0).toFixed(2);
  let subtotal =
    cart &&
    cart
      .reduce((acc, item) => acc + Number(item.price) * item.quantity, 0)
      .toFixed(2);
  let total = subtotal - subtotal * (discount / 100);
  total = total.toFixed(2); 

  return (
    <section className="orderMainCart">
      <div className="first-div">
        <div>
          {/* <h1>{t("Order & Payment Methods")}</h1> */}
        </div>
      </div>

      <div className="second-div">
        <div>
          <UserAddressForm
            user={user}
            userProfile={userProfile}
            setUserProfile={setUserProfile}
          />
        </div>
        <OrderItems cart={cart} />
      </div>
      <div className="last-div second-div">
        <div className="w-100">
          <div>
           
              <PaymentScreens
                userProfile={userProfile}
                subtotal={subtotal}
                total={total}
                user={user}
                userAffiliateInfo={userAffiliateInfo}
              />
           
          </div>
          <TotalAmounts
            showPaymentButtons={showPaymentButtons}
            setShowPaymentButtons={setShowPaymentButtons}
            discount={discount}
            subtotal={subtotal}
            total={total}
          />
        </div>
      </div>
    </section>
  );
};

export default OrderPageBodyComponent;
