export const REQUEST_URL = process.env.REACT_APP_REQUEST_URL;
export const WS_REQUEST_URL = process.env.REACT_APP_WS_URL;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

export const TIME_ZONE = process.env.REACT_APP_TIME_ZONE;


export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_PROFILE_REQUEST_URL = process.env.REACT_APP_GOOGLE_PROFILE_REQUEST_URL;


