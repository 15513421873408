import React, { useState } from "react";
import "./reset_password.css";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { reset_password } from "../../../../actions/user/auth";
import Navbar from "../../Landing-page/Navbar/navbar.jsx";

function ResetPassword() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const isLoading = state.user.loading;
  const error = state.user.error;
  const isSuccess = state.user.isSuccess;
  const [formData, setFormData] = useState({
    email: "",
  });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    const { email } = formData;
    dispatch(reset_password(email));
  };
  if (isSuccess) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <Navbar />
      <div className="logIn">
        <div className="form-modal">
          <div className="form-toggle">
            <button id="login-toggle" disabled>
              Reset Password Request
            </button>
          </div>

          <div id="login-form">
            <form onSubmit={(e) => onSubmit(e)}>
              <input
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={(e) => onChange(e)}
                style={{borderRadius:"0px"}}
              />
              <button type="submit" className="btn login">
                {isLoading ? (
                  <Loader
                    style={{ display: "inline-block" }}
                    type="ThreeDots"
                    color="white"
                    height={5}
                    width={30}
                  />
                ) : (
                  ""
                )}
                Reset
              </button>
              {(error != null || error != undefined)
                && Object.keys(error.data).map((key, index) => (
                    <ul key={index} style={{ paddingLeft: "10px" }}>
                      <li
                        style={{
                          color: "#E0115F",
                          fontSize: "15px",
                          listStyleType: "square",
                        }}
                      >
                        <span>
                          <strong>{key.toUpperCase()}</strong> :{" "}
                        </span>
                        <span>
                          <strong>{error.data[key]}</strong>
                        </span>
                      </li>
                    </ul>
                  ))}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
