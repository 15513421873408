import "./reset_password_confirm.css";
import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../Landing-page/Navbar/navbar.jsx";
import { reset_password_confirm } from "../../../../actions/user/auth";
import { ErrorToast } from "../../../../components/toaster/toast.jsx";

function ResetPasswordConfirm() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const error = state.user.error;
  const isLoading = state.user.loading;
  const isSuccess = state.user.isSuccess;

  const [formData, setFormData] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    rePassword: false,
  });
  const togglePasswordVisibility = (field) => {
    setPasswordVisibility({
      ...passwordVisibility,
      [field]: !passwordVisibility[field],
    });
  };
  let { uid, token } = useParams();
  const { new_password, re_new_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (new_password === re_new_password) {
      dispatch(
        reset_password_confirm(uid, token, new_password, re_new_password)
      );
    } else {
      ErrorToast("");
    }
  };

  if (isSuccess) return <Redirect to="/signin" />;

  return (
    <>
      <Navbar />
      <div className="logIn">
        <div className="form-modal">
          <div className="form-toggle">
            <button id="login-toggle" disabled>
              Reset Password
            </button>
          </div>

          <div id="login-form">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="password-input">
                <input
                  type={passwordVisibility.password ? "text" : "password"}
                  placeholder="new password"
                  name="new_password"
                  value={new_password}
                  minLength="6"
                  onChange={(e) => onChange(e)}
                  style={{borderRadius:"0px"}}
                />
                {passwordVisibility.password ? (
                  <i
                    className="far fa-eye"
                    onClick={() => togglePasswordVisibility("password")}
                  ></i>
                ) : (
                  <i
                    className="far fa-eye-slash"
                    onClick={() => togglePasswordVisibility("password")}
                  ></i>
                )}
              </div>
              <div className="password-input">
                <input
                  type={passwordVisibility.rePassword ? "text" : "password"}
                  placeholder="confirm new password"
                  name="re_new_password"
                  value={re_new_password}
                  minLength="6"
                  onChange={(e) => onChange(e)}
                  style={{ borderRadius: "0px" }}
                />
                {passwordVisibility.rePassword ? (
                  <i
                    className="far fa-eye"
                    onClick={() => togglePasswordVisibility("rePassword")}
                  ></i>
                ) : (
                  <i
                    className="far fa-eye-slash"
                    onClick={() => togglePasswordVisibility("rePassword")}
                  ></i>
                )}
              </div>
              <button type="submit" className="btn login">
                {isLoading ? (
                  <Loader
                    style={{ display: "inline-block" }}
                    type="ThreeDots"
                    color="white"
                    height={5}
                    width={30}
                  />
                ) : (
                  ""
                )}
                Reset
              </button>
              {error != null || error != undefined
                ? Object.keys(error.data).map((key, index) => (
                    <ul key={index} style={{ paddingLeft: "10px" }}>
                      <li
                        style={{
                          color: "blue",
                          fontSize: "15px",
                          listStyleType: "square",
                        }}
                      >
                        <span>
                          <strong>{key.toUpperCase()}</strong> :{" "}
                        </span>
                        <span>
                          <strong>{error.data[key]}</strong>
                        </span>
                      </li>
                    </ul>
                  ))
                : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordConfirm;
