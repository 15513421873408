import React, { useEffect } from "react";
import "./winner.css";
import moment from "moment";
import Swiper from "swiper/bundle";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Tree from "../../../../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getArchivedCompetiotions } from "../../../../../actions/winnerPodiumActions";

const WinnerSection = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  
  const state = useSelector((state) => state);
  const { loading, top5Winners } = state.winnersPodium;
  useEffect(() => {
    async function fetchData() {
      await dispatch(getArchivedCompetiotions("top5"));
      new Swiper(".second.swiper", {
        slidesPerView: 1,
        speed: 1000,
        simulateTouch: false,
        autoplay: {
          delay: 4000,
        },
        pagination: {
          el: ".second.swiper-pagination",
          clickable: true,
        },
      });
    }
    fetchData();
  }, []);
  return (
    <section className="section-3">
      <h1 style={{ fontWeight: 200 }}>{t("Top 5 all time winners")}</h1>
      <div className="second swiper-pagination"></div>
      <div className="second swiper">
        <div className="second swiper-wrapper">
          {loading && (
            <div
              className="items-container"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
            </div>
          )}
          {top5Winners &&
            top5Winners.map((obj) => (
              <div className="second swiper-slide" key={obj.id}>
                <div
                  style={{
                    backgroundImage: `url(${obj.images.image})`,
                  }}
                ></div>
                <div>
                  <figure>
                    <img src={Tree} alt="" />
                  </figure>
                  <h2 style={{ fontSize: "30px", textTransform: "capitalize" }}>
                    {obj.competition_title}
                  </h2>
                  <h3>{obj.title}</h3>
                  <h4>
                    {t("won by")} {obj.winner_name}
                  </h4>
                  <p>{obj.winner_msg}</p>
                  <h5 className="mt-3">
                    {t("Winning ticket")} {obj.winner_ticket}
                  </h5>
                  <h5 className="mt-3">
                    {t("Live draw from")} {moment(obj.date).format("LL")}
                  </h5>
                </div>
              </div>
            ))}
        </div>
      </div>
      <h1 className="loveit mx-auto" style={{ fontWeight: "900" }}>
        Love it. &nbsp; Live it. &nbsp; Paradise.
      </h1>
    </section>
  );
};

export default WinnerSection;
