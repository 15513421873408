import * as types from "../actions/types";

const initialState = {
  error: null,
  loading: false,
  archivedCompetitions: [],
  top5Winners: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.WINNERS_PODIUM_REQUEST_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case types.GET_WINNERS_PODIUM_REQUEST_SUCCESS:
      return {
        ...state,
        archivedCompetitions: payload,
        error: null,
        loading: false,
      };
    case types.GET_WINNERS_PODIUM_REQUEST_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.GET_TOP_5_WINNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        top5Winners: payload,
      };
    case types.GET_TOTAL_GIVE_AWAY_FAIL:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
