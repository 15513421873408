import React, { useEffect } from "react";
import "./section-1.css";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { getCompetitions } from "../../../../actions/landingPageActions";
import DisplayCompetitions from "../../../../components/displaycompetitions/DisplayCompetitions.jsx";

const Section1 = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const loading = state.competitions.loading;
  useEffect(() => {
    dispatch(getCompetitions());
  }, []);

  if (loading) {
    return (  
      <div
        className="items-container"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loader type="ThreeDots" color="#00FFEA" height={80} width={80} />
      </div>
    );
  }
  return <DisplayCompetitions />;
};

export default Section1;
